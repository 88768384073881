import {
  GanttJob,
  GanttAnnotation,
  GanttActivity,
  GanttActivityStates,
  GanttRequest,
} from '@/clients/ops/model';
import { JobEventType } from '@/clients/ops';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import {
  encodeTimestamp,
  decodeTimestamp,
} from '@/components/GanttD3/ganttModel';

const newJobInterval = 5000;
const minActivityDuration = 5000; // min duration of a single activity in milliseconds
const maxActivityDuration = 10000; // max random duration added to an activity
const minActivityCount = 3;
const maxActivityCount = 5;
const maxLanesToReturn = 20;
const fakeAnnotationCount = 5;

const fakeGanttData: GanttJob[] = [];
type FakeResource = {
  id: string;
  index: number;
  assignedBoxes: GanttActivity[];
};
const fakeResources: FakeResource[] = [];
const zeroPad = (num, places) => String(num).padStart(places, '0');
const addFakeResource = () => {
  const newIdx = fakeResources.length;
  fakeResources.push({
    id: 'Fake_Resource_' + zeroPad(newIdx, 4),
    index: newIdx,
    assignedBoxes: [],
  });
  return newIdx;
};
// prepopulate with available resources
for (let i = 0; i < 5; i++) {
  addFakeResource();
}
const boxesOverlap = (box1: GanttActivity, box2: GanttActivity) => {
  return box1.start <= box2.end && box2.start <= box1.end;
};
const resourceAvailable = (box: GanttActivity, fakeResource: FakeResource) => {
  for (const el of fakeResource.assignedBoxes) {
    if (boxesOverlap(box, el)) {
      return false;
    }
  }
  return true;
};

function addManualActivity(
  name: string,
  start: DateTime,
  end: DateTime,
  state: GanttActivityStates,
  actorId: string
): GanttActivity {
  return {
    name,
    id: `fakeManualActivity_${uuid()}`,
    start: encodeTimestamp(start),
    end: encodeTimestamp(end),
    state: state,
    actionId: `actionId_${uuid()}`,
    actionType: 'ACTION_TYPE_UNKNOWN',
    scheduledActor: {
      actorId,
    }, // need to consider these now
    constraints: null,
    compatibleActorIds: [],
    actorRequestIndex: null,
    projected: false,
  };
}
function rando(num: number): number {
  return num + (Math.random() - 0.5) * (num / 5);
}
function makeManualData(name: string, start: DateTime): GanttJob {
  const activities: GanttActivity[] = [];
  let nextStart = start.plus(rando(1000));
  activities.push(
    addManualActivity(
      'Find Stacker',
      (nextStart = nextStart.plus(rando(1000))),
      (nextStart = nextStart.plus(rando(5000))),
      'ACTIVITY_TYPE_ACTION_RUNNING',
      'Cellario'
    )
  );
  activities.push(
    addManualActivity(
      'Load Plates',
      (nextStart = nextStart.plus(rando(4000))),
      (nextStart = nextStart.plus(rando(54000))),
      'ACTIVITY_TYPE_ASSISTANCE_NEEDED',
      'Assistant'
    )
  );
  activities.push(
    addManualActivity(
      'Start Cellario',
      (nextStart = nextStart.plus(rando(1000))),
      (nextStart = nextStart.plus(rando(5000))),
      'ACTIVITY_TYPE_ACTION_RUNNING',
      'Cellario'
    )
  );
  let finished = false;
  while (!finished) {
    activities.push(
      addManualActivity(
        'Check Plate Count',
        (nextStart = nextStart.plus(rando(1000))),
        (nextStart = nextStart.plus(rando(5000))),
        'ACTIVITY_TYPE_ACTION_RUNNING',
        'Cellario'
      )
    );
    if (Math.random() < 0.5) {
      activities.push(
        addManualActivity(
          'Wrong Plate Quantity',
          (nextStart = nextStart.plus(rando(4000))),
          (nextStart = nextStart.plus(rando(12000))),
          'ACTIVITY_TYPE_ASSISTANCE_NEEDED',
          'Assistant'
        )
      );
    } else {
      finished = true;
      let innerFinished = false;
      while (!innerFinished) {
        activities.push(
          addManualActivity(
            'Check Plate Details',
            (nextStart = nextStart.plus(rando(1000))),
            (nextStart = nextStart.plus(rando(5000))),
            'ACTIVITY_TYPE_ACTION_RUNNING',
            'Cellario'
          )
        );
        if (Math.random() < 0.5) {
          innerFinished = true;
        } else {
          finished = false;
          activities.push(
            addManualActivity(
              'Wrong Plate Details',
              (nextStart = nextStart.plus(rando(4000))),
              (nextStart = nextStart.plus(rando(12000))),
              'ACTIVITY_TYPE_ASSISTANCE_NEEDED',
              'Assistant'
            )
          );
        }
      }
    }
  }
  activities.push(
    addManualActivity(
      'Create Cellario Order',
      (nextStart = nextStart.plus(rando(1000))),
      (nextStart = nextStart.plus(rando(5000))),
      'ACTIVITY_TYPE_ACTION_RUNNING',
      'Cellario'
    )
  );
  activities.push(
    addManualActivity(
      'Run Cellario Order',
      (nextStart = nextStart.plus(rando(1000))),
      (nextStart = nextStart.plus(rando(5000))),
      'ACTIVITY_TYPE_ACTION_RUNNING',
      'Cellario'
    )
  );
  return {
    name,
    id: `id_${uuid()}`,
    workflowId: `workflowId_${uuid()}`,
    start: encodeTimestamp(start),
    end: encodeTimestamp(nextStart.plus(rando(5000))),
    activities,
    annotations: [],
  };
}

export default async function getFakeGanttData(
  initialJobs = 5
): Promise<GanttRequest> {
  const nowTime = DateTime.now();
  if (fakeGanttData.length === 0) {
    if (initialJobs < 0) {
      // manually smash on some test cases here
      fakeGanttData.push(
        makeManualData('Run 1', nowTime.minus(60 * 60 * 1000))
      );
      fakeGanttData.push(
        makeManualData('Run 2', nowTime.minus(59 * 60 * 1000))
      );
      fakeGanttData.push(
        makeManualData('Run 3', nowTime.minus(58 * 60 * 1000))
      );
    } else {
      const fakeStartTime = nowTime.minus(initialJobs * newJobInterval);
      for (let i = 0; i < initialJobs; i++) {
        fakeGanttData.push(
          generateFakeJob(fakeStartTime.plus(i * newJobInterval))
        );
      }
    }
  } else {
    // modify the existing data
    const latestStartDate = decodeTimestamp(
      fakeGanttData[fakeGanttData.length - 1].start
    );
    if (latestStartDate.plus(newJobInterval) < nowTime) {
      // add a new lane every newJobInteral milliseconds
      fakeGanttData.push(generateFakeJob(nowTime));
    }
    // also need a mechanism to drop old lanes
    // for now, just max out at a number of lanes
    if (fakeGanttData.length > maxLanesToReturn) {
      fakeGanttData.shift();
    }
  }
  // need to run it through the "current time" filter here
  const trimmedJobs = fakeGanttData
    .map((val) => {
      return trimFakeData(val, nowTime);
    })
    .filter((val): val is GanttJob => val !== undefined);
  return {
    projectionsComputedAt: encodeTimestamp(nowTime),
    jobs: trimmedJobs.reverse(),
    actorStatuses: [],
  };
}

// trims fake future events off of gantt data
function trimFakeData(job: GanttJob, nowTime: DateTime): GanttJob | undefined {
  const startTime = decodeTimestamp(job.start);
  // if this job hasn't started yet, remove it entirely
  if (startTime > nowTime) {
    return undefined;
  }
  const endTime = decodeTimestamp(job.end);
  // if this job is finished, return all of it
  if (endTime < nowTime) {
    return structuredClone(job);
  }

  // this job is still ongoing, filter out any future stuff
  const filteredActivities: GanttActivity[] = [];
  for (const activity of job.activities) {
    const startTime = decodeTimestamp(activity.start);
    if (startTime > nowTime) {
      // once we hit an activity in the future, stop doing this
      break;
    }
    const endTime = decodeTimestamp(activity.end);
    // if this activity is completed, just push it
    if (endTime < nowTime) {
      filteredActivities.push(activity);
      continue;
    }
    // if this activity is in process, push part of it, then break;
    filteredActivities.push({
      ...activity,
      end: encodeTimestamp(nowTime),
      state: 'ACTIVITY_TYPE_ACTION_RUNNING',
    });
    break;
  }
  const filteredAnnotations: GanttAnnotation[] = [];
  for (const annotation of job.annotations) {
    const time = decodeTimestamp(annotation.timestamp);
    if (time < nowTime) {
      filteredAnnotations.push(annotation);
    }
  }
  const filteredJob: GanttJob = {
    name: job.name,
    id: job.id,
    workflowId: job.workflowId,
    start: job.start,
    end: decodeTimestamp(job.end) < nowTime ? job.end : '',
    activities: filteredActivities,
    annotations: filteredAnnotations,
  };
  return filteredJob;
}
let fakeJobCount = 0;
function generateFakeJob(startTime: DateTime): GanttJob {
  const fakeActivities = generateFakeActivities(
    startTime,
    minActivityCount + Math.random() * (maxActivityCount - minActivityCount)
  );
  const endTime = addGapTime(
    decodeTimestamp(fakeActivities[fakeActivities.length - 1].end)
  );
  const fakeAnnotations = generateFakeAnnotations(startTime, endTime);
  const ganttJob: GanttJob = {
    name: `Fake_Job_${fakeJobCount}`,
    id: uuid(),
    workflowId: uuid(),
    start: encodeTimestamp(startTime),
    end: encodeTimestamp(endTime),
    activities: fakeActivities,
    annotations: fakeAnnotations,
  };
  fakeJobCount++;

  return ganttJob;
}

function pickRandomState(): GanttActivityStates {
  // RUNNING: '#0B83FF',
  // ACTIVITY_TYPE_ACTION_RUNNING: '#0B83FF',
  // ACTIVITY_TYPE_ASSISTANT_RUNNING: '#0B83FF',

  // FINISHED: '#0B83FF',

  const options: GanttActivityStates[] = [
    'ACTIVITY_TYPE_ASSISTANCE_NEEDED',
    'ACTIVITY_TYPE_ERROR',
    'ACTIVITY_TYPE_PAUSED',
    'ACTIVITY_TYPE_UNKNOWN',
    'ACTIVITY_TYPE_ON_HOLD',
  ];
  return options[Math.floor(Math.random() * options.length)];
}

function addGapTime(time: DateTime): DateTime {
  return time.plus(Math.random() * Math.random() * maxActivityDuration);
}

function generateFakeActivities(
  startTime: DateTime,
  numActivities: number
): GanttActivity[] {
  const fakeActivities: GanttActivity[] = [];
  let currentTime = startTime;
  for (let i = 0; i < numActivities; i++) {
    // to add gaps before/during jobs
    currentTime = addGapTime(currentTime);
    //march forward until we create activities all across this job
    const activityDuration =
      minActivityDuration +
      Math.random() * (maxActivityDuration - minActivityDuration);
    // build initial fake activity
    const fakeActivity: GanttActivity = {
      name: 'activity name ' + i,
      id: 'fake_gantt_id_' + uuid(),
      start: encodeTimestamp(currentTime),
      end: encodeTimestamp(currentTime.plus(activityDuration)),
      state: pickRandomState(),
      actionId: uuid(),
      actionType: 'ACTION_TYPE_UNKNOWN',
      scheduledActor: null,
      constraints: null,
      compatibleActorIds: [],
      actorRequestIndex: null,
      projected: false,
    };

    // find available fake resources
    const availableResources = fakeResources.filter((el) => {
      return resourceAvailable(fakeActivity, el);
    });
    // randomly pick one, or make a new one
    let foundIdx = -1;
    if (availableResources.length > 0) {
      foundIdx = Math.floor(Math.random() * availableResources.length);
    }
    if (foundIdx < 0) {
      // no free resource found, add a new one
      foundIdx = addFakeResource();
    } else {
      foundIdx = availableResources[foundIdx].index;
    }
    // reserve the resource
    fakeActivity.scheduledActor = { actorId: fakeResources[foundIdx].id };
    fakeResources[foundIdx].assignedBoxes.push(fakeActivity);

    fakeActivities.push(fakeActivity);
    currentTime = currentTime.plus(activityDuration);
  }
  return fakeActivities;
}

const annotationTypes = [
  JobEventType.JOB_SPAWNED_CHILD,
  JobEventType.JOB_FILE_CAPTURED,
  JobEventType.JOB_CANCELLED,
  JobEventType.ACTION_RETRIED,
  JobEventType.ACTION_MANUAL_OVERRIDE,
  JobEventType.ACTION_FILE_CAPTURE_INITIATED,
  JobEventType.JOB_CANCEL_REQUESTED,
  JobEventType.JOB_PAUSE_REQUESTED,
  JobEventType.JOB_RESUME_REQUESTED,
  JobEventType.JOB_NOTE_ADDED,
  JobEventType.JOB_NOTE_UPDATED,
  JobEventType.JOB_UNSCHEDULE_REQUESTED,
  JobEventType.JOB_CONFIG_CHANGED,
  JobEventType.JOB_BLOCKED,
];
function generateFakeAnnotations(
  startTime: DateTime,
  endTime: DateTime
): GanttAnnotation[] {
  const duration = endTime.diff(startTime, 'minutes').minutes;
  // this is not right yet...
  const fakeAnnotations: GanttAnnotation[] = [];
  for (let i = 0; i < fakeAnnotationCount; i++) {
    const randTime = Math.random() * duration;
    const fakeAnnotation: GanttAnnotation = {
      id: 'fake_gantt_annotation_' + uuid(),
      type: annotationTypes[Math.floor(Math.random() * annotationTypes.length)],
      timestamp: encodeTimestamp(startTime.plus({ minutes: randTime })),
    };
    fakeAnnotations.push(fakeAnnotation);
  }
  return fakeAnnotations;
}

// TRY TO GET THIS WORKING AGAIN?
// just create one of every type of point event onto every lane for testing
// this is only for testing...
// const createTestPoints = (
//   startTimestamp: string,
//   endTimestamp: string
// ): GanttPointEvent[] => {
//   const annotationTypes = [
//     JobEventType.JOB_SPAWNED_CHILD,
//     JobEventType.JOB_FILE_CAPTURED,
//     JobEventType.JOB_CANCELLED,
//     JobEventType.ACTION_RETRIED,
//     JobEventType.ACTION_MANUAL_OVERRIDE,
//     JobEventType.ACTION_FILE_CAPTURE_INITIATED,
//     JobEventType.JOB_CANCEL_REQUESTED,
//     JobEventType.JOB_PAUSE_REQUESTED,
//     JobEventType.JOB_RESUME_REQUESTED,
//     JobEventType.JOB_NOTE_ADDED,
//     JobEventType.JOB_NOTE_UPDATED,
//     JobEventType.JOB_UNSCHEDULE_REQUESTED,
//     JobEventType.JOB_CONFIG_CHANGED,
//     JobEventType.JOB_BLOCKED,
//   ];
//   // calculate a time increment
//   const start = GanttController.convertTimestamp(startTimestamp);
//   const end = GanttController.convertTimestamp(endTimestamp);
//   const duration = end.diff(start).as('milliseconds');
//   const increment = duration / annotationTypes.length;
//   return annotationTypes.map((type, index) => {
//     const time = start.plus({ milliseconds: increment * index });
//     const point: GanttPointEvent = {
//       type,
//       time,
//       id: index.toString(),
//     };
//     return point;
//   });
// };
