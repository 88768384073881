
import { defineComponent, computed, ref, PropType, watch } from 'vue';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import { sortBy } from 'lodash';
import OpsController, {
  JobEvent,
  JobEventType,
  EventSourceType,
  PublicErrorSeverity,
  TerminalEventTypes,
} from '@/clients/ops';
import UserController from '@/clients/users';
import UIController from '@/clients/ui';
import { ActionType } from '@/clients/action';
import { formatDateTime, formatTimeDifference } from '@/pages/utils';
import assistantActionIcon from '@/assets/icons/timeline-assistant-action.svg';
import assistantIcon from '@/assets/icons/timeline-assistant-circle.svg';
import cancelledIcon from '@/assets/icons/timeline-cancelled-circle.svg';
import cancelledActionIcon from '@/assets/icons/timeline-cancelled-action.svg';
import completedIcon from '@/assets/icons/timeline-completed-circle.svg';
import completedActionIcon from '@/assets/icons/timeline-completed-action.svg';
import assistantCompletedIcon from '@/assets/icons/timeline-completed-assistant.svg';
import assistantSkippedIcon from '@/assets/icons/timeline-skipped-assistant.svg';
import pausedIcon from '@/assets/icons/timeline-paused-circle.svg';
import pausedActionIcon from '@/assets/icons/timeline-paused-action.svg';
import onHoldIcon from '@/assets/icons/timeline-on-hold-circle.svg';
import onHoldActiveIcon from '@/assets/icons/timeline-on-hold-active-circle.svg';
import onHoldActionIcon from '@/assets/icons/timeline-on-hold-action.svg';
import erroredIcon from '@/assets/icons/timeline-error-circle.svg';
import errorActionIcon from '@/assets/icons/timeline-error-action.svg';
import resumedIcon from '@/assets/icons/timeline-resumed-circle.svg';
import retryIcon from '@/assets/icons/timeline-retry-circle.svg';
import pendingActionIcon from '@/assets/icons/timeline-pending-action.svg';
import runningIcon from '@/assets/icons/timeline-running-circle.svg';
import runningActionIcon from '@/assets/icons/timeline-running-action.svg';
import skippedIcon from '@/assets/icons/timeline-skipped-circle.svg';
import skippedActionIcon from '@/assets/icons/timeline-skipped-action.svg';
import infoIcon from '@/assets/icons/timeline-info-circle.svg';
import skippedStepIcon from '@/assets/icons/timeline-skipped-step-circle.svg';
import completedStepIcon from '@/assets/icons/timeline-completed-step-circle.svg';
import fileIcon from '@/assets/icons/timeline-file-circle.svg';
import spawnedIcon from '@/assets/icons/timeline-spawn-circle.svg';
import dialogIcon from '@/assets/icons/timeline-prompt-action.svg';
import { WaitTypeEvents } from '@/clients/ops/model';

const eventTypesRequiringHint = [
  JobEventType.ACTION_PAUSED,
  JobEventType.ACTION_RETRY_REQUESTED,
];

const eventTypesForStateDerivation = [
  ...TerminalEventTypes,
  JobEventType.ACTION_PAUSED,
  JobEventType.ACTION_RESUMED,
  JobEventType.ACTION_AT_ACTOR,
  JobEventType.ACTION_ERRORED,
  JobEventType.ACTION_REVOKED,
  JobEventType.ASSISTANCE_NEEDED,
  JobEventType.GENERAL_ERROR,
];

export default defineComponent({
  name: 'JobActivityTimelineItem',
  props: {
    event: {
      type: Object as PropType<JobEvent>,
      required: true,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    expandByDefault: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      required: true,
    },
    nextEvent: {
      type: Object as PropType<JobEvent>,
      required: false,
    },
  },
  emits: ['open-details'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const expert = computed(() => UIController.Instance.expert);
    const isArtificialUser = computed(
      () => UserController.Instance.isActingAsArtificialUser
    );

    const eventSourceText = computed(() => {
      switch (props.event.initiatorType) {
        case EventSourceType.EVENT_SOURCE_TYPE_ACTION: {
          return 'actionapi';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_ACTOR: {
          return 'actor';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_ASSISTANT: {
          return 'assistantapi';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_BATCHING_SERVICE: {
          return 'batching-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_CONFIG_SERVICE: {
          return 'config-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_EXPRESSION_SERVICE: {
          return 'expression-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_JOBAPI: {
          return 'jobapi';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_JOB_EXECUTOR: {
          return 'jobexecutor';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_JOB_SCHEDULER: {
          return 'scheduler';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_JOB_SERVICE: {
          return 'jobs-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_LABMANAGER: {
          return 'labmanager';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_PUBLIC_ERRORS: {
          return 'public-errors';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_RESULTS_SERVICE: {
          return 'results-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_STATE_SERVICE: {
          return 'state-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_USER: {
          return 'user initiated';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_WORKFLOW_SERVICE: {
          return 'workflow-service';
        }
        case EventSourceType.EVENT_SOURCE_TYPE_UNKNOWN:
        default: {
          return 'Unknown source';
        }
      }
    });

    const getWaitTime = (
      nextTimestamp: string | number | Date | undefined = props.nextEvent
        ?.timestamp,
      timestamp: string | number | Date | undefined = props.event?.timestamp
    ) => {
      if (!timestamp || !nextTimestamp) {
        return '';
      }

      return formatTimeDifference(timestamp, nextTimestamp).short;
    };

    const eventText = computed(() => {
      if (props.event.message) {
        return props.event.message;
      } else if (
        isAssistantStep.value &&
        stepName.value &&
        props.event.type !== JobEventType.ACTION_FILE_CAPTURE_INITIATED &&
        props.event.type !== JobEventType.ACTION_FILE_CAPTURED
      ) {
        return stepName.value;
      }
      let text = '';
      switch (props.event.type) {
        case JobEventType.ACTION_AT_ACTOR:
        case JobEventType.ACTION_STARTED:
        case JobEventType.ACTION_AT_LABMANAGER: {
          text = 'Started';
          break;
        }
        case JobEventType.JOB_STARTED: {
          text = 'Job started';
          break;
        }
        case JobEventType.ACTION_COMPLETED: {
          text = 'Completed';
          break;
        }
        case JobEventType.JOB_COMPLETED: {
          text = 'Job completed';
          break;
        }
        case JobEventType.ACTION_CANCELLED: {
          text = 'Canceled';
          break;
        }
        case JobEventType.JOB_CANCELLED: {
          text = 'Job canceled';
          break;
        }
        case JobEventType.JOB_CANCEL_REQUESTED: {
          text = 'Cancel requested';
          break;
        }
        case JobEventType.JOB_CREATED: {
          text = 'Job created';
          break;
        }
        case JobEventType.JOB_PAUSE_REQUESTED: {
          text = 'Pause requested';
          break;
        }
        case JobEventType.JOB_PAUSED: {
          if (props.nextEvent) {
            text = `Job paused for ${getWaitTime()}`;
          } else {
            text = 'Job paused';
          }
          break;
        }
        case JobEventType.JOB_SCHEDULE_COMPLETED: {
          text = 'Job scheduled';
          break;
        }
        case JobEventType.JOB_UNSCHEDULE_COMPLETED: {
          text = 'Job unscheduled';
          break;
        }
        case JobEventType.JOB_UNSCHEDULE_FAILED: {
          text = 'Job unschedule failed';
          break;
        }
        case JobEventType.JOB_WAITING_FOR_ACTOR: {
          if (!props.nextEvent) {
            text = 'Waiting for resource availability';
          } else {
            text = `Waited ${getWaitTime()} for resource availability`;
          }
          break;
        }
        case JobEventType.JOB_WAITING_FOR_ACTION_START: {
          if (!props.nextEvent) {
            text = `Waiting for user-defined delay`;
          } else {
            text = `Waited ${getWaitTime()} for user-defined delay`;
          }
          break;
        }
        case JobEventType.JOB_WAITING_FOR_REVOCATION_GROUP: {
          if (!props.nextEvent) {
            text = 'Waiting for revocation group';
          } else {
            text = `Waited ${getWaitTime()} for revocation group`;
          }
          break;
        }
        case JobEventType.ACTION_PAUSE_REQUESTED: {
          text = 'Pause requested';
          break;
        }
        case JobEventType.ACTION_PAUSED: {
          text = 'Action Paused';
          break;
        }
        case JobEventType.ACTION_REVOKED: {
          text = 'On hold';
          break;
        }
        case JobEventType.ACTION_RESUME_REQUESTED:
        case JobEventType.JOB_RESUME_REQUESTED: {
          text = 'Resume requested';
          break;
        }
        case JobEventType.ACTION_RESUMED: {
          text = 'Action resumed';
          break;
        }
        case JobEventType.JOB_RESUMED: {
          text = 'Job resumed';
          break;
        }
        case JobEventType.ACTION_ERRORED: {
          text = 'Error';
          break;
        }
        case JobEventType.ACTION_RETRY_REQUESTED: {
          text = 'Retried';
          break;
        }
        case JobEventType.ACTION_RETRY_FAILED: {
          text = 'Retry failed';
          break;
        }
        case JobEventType.ACTION_SIGNATURE_CREATED: {
          text = props.event.details?.signingReason || 'Signed off';
          break;
        }
        case JobEventType.ACTION_SIGN_OFF_RULES_SATISFIED: {
          text = 'Sign off rules satisfied';
          break;
        }
        case JobEventType.ACTION_MANUAL_OVERRIDE: {
          text = 'Skipped';
          break;
        }
        case JobEventType.ACTION_RETRIED: {
          text = 'Retry successful';
          break;
        }
        case JobEventType.ASSISTANCE_NEEDED: {
          text = 'Assistance needed';
          break;
        }
        case JobEventType.ASSISTANT_SKIPPED: {
          text = 'Marked complete';
          break;
        }
        case JobEventType.ASSISTANT_STEP_SKIPPED: {
          text = 'Step skipped';
          break;
        }
        case JobEventType.ASSISTANT_TASK_SKIPPED: {
          text = 'Task skipped';
          break;
        }
        case JobEventType.JOB_FILE_CAPTURED: {
          text = 'File uploaded';
          break;
        }
        case JobEventType.ACTION_FILE_CAPTURE_INITIATED: {
          text = 'File uploading...';
          break;
        }
        case JobEventType.ACTION_FILE_CAPTURED: {
          text = 'File uploaded';
          break;
        }
        case JobEventType.JOB_SPAWNED_CHILD: {
          const name = OpsController.Instance.getJob(
            props.event.details?.childJobId
          ).name;
          const labId = OpsController.Instance.getJob(
            props.event.details?.childJobId
          ).labId;
          if (!name || !labId) {
            OpsController.Instance.dispatchGetJobNames([
              props.event.details?.childJobId,
            ]);
          }
          // Using || instead of ?? since ?? doesn't fall through empty strings
          text = `Created ${name || 'job'}`;
          break;
        }
        default: {
          text = props.event.type;
        }
      }
      if (
        props.event.initiatorId &&
        props.event.initiatorType === EventSourceType.EVENT_SOURCE_TYPE_USER
      ) {
        text += ` by ${
          UserController.Instance.getUser(props.event.initiatorId)?.name ||
          'Unknown'
        }`;
      } else if (
        props.event.initiatorId &&
        props.event.initiatorType ===
          EventSourceType.EVENT_SOURCE_TYPE_EXPRESSION_SERVICE
      ) {
        // the initiator ID should be a job
        const parentName = OpsController.Instance.getJob(
          props.event.initiatorId
        ).name;
        if (!parentName) {
          OpsController.Instance.dispatchGetJobNames([props.event.initiatorId]);
        }
        text += ` by ${parentName}`;
      }

      if (isArtificialUser.value) {
        text = `${text} (${eventSourceText.value})`;
      }

      return text;
    });

    const eventTimestamp = computed(() => {
      return formatDateTime(props.event.timestamp, true);
    });

    // TODO: use actionType once it's available
    const isAssistant = computed(() =>
      props.event.children?.some((c) => c.actionType === ActionType.ASSISTANT)
    );

    const isDialogAction = computed(() =>
      props.event.children?.some((c) => c.actionType === ActionType.DIALOG)
    );

    const eventIcon = computed(() => {
      switch (props.event.type) {
        case JobEventType.ACTION_AT_ACTOR:
        case JobEventType.ACTION_STARTED:
        case JobEventType.ACTION_AT_LABMANAGER:
        case JobEventType.ACTION_COMPLETED:
        case JobEventType.JOB_STARTED:
        case JobEventType.JOB_COMPLETED:
        case JobEventType.JOB_CREATED:
        case JobEventType.JOB_SCHEDULE_COMPLETED:
        default: {
          if (
            props.event.actionType === ActionType.ASSISTANT_STEP ||
            props.event.actionType === ActionType.ASSISTANT_TASK
          ) {
            return completedStepIcon;
          }
          return completedIcon;
        }
        case JobEventType.PLACEHOLDER: {
          if (props.event.children?.length) {
            // consider only events that are in eventTypesForStateDerivation
            const childrenToConsider = props.event.children.filter((c) =>
              eventTypesForStateDerivation.includes(c.type)
            );
            const terminalEvent = childrenToConsider.find((e) =>
              TerminalEventTypes.includes(e.type)
            );
            if (
              isAssistant.value &&
              !terminalEvent &&
              childrenToConsider[0].type !== JobEventType.ACTION_REVOKED
            ) {
              return assistantActionIcon;
            } else if (isDialogAction.value && !terminalEvent) {
              return dialogIcon;
            } else if (terminalEvent) {
              switch (terminalEvent.type) {
                case JobEventType.ACTION_COMPLETED: {
                  return isAssistant.value
                    ? assistantCompletedIcon
                    : completedActionIcon;
                }
                case JobEventType.ACTION_CANCELLED: {
                  return cancelledActionIcon;
                }
                case JobEventType.ACTION_MANUAL_OVERRIDE: {
                  return skippedActionIcon;
                }
                case JobEventType.ASSISTANT_SKIPPED: {
                  return assistantSkippedIcon;
                }
                default: {
                  return runningActionIcon;
                }
              }
            } else {
              // check the latest child of this event
              const latestChild = childrenToConsider[0];
              switch (latestChild.type) {
                case JobEventType.ACTION_PAUSED: {
                  return pausedActionIcon;
                }
                case JobEventType.ACTION_REVOKED: {
                  return onHoldActionIcon;
                }
                case JobEventType.ACTION_ERRORED: {
                  return errorActionIcon;
                }
                case JobEventType.GENERAL_ERROR: {
                  const severity = latestChild.details.publicError.severity;
                  if (
                    severity === PublicErrorSeverity.CRITICAL ||
                    severity === PublicErrorSeverity.ERROR
                  ) {
                    return errorActionIcon;
                  } else {
                    return runningActionIcon;
                  }
                }
                default: {
                  return runningActionIcon;
                }
              }
            }
          }
          return pendingActionIcon;
        }
        case JobEventType.JOB_PAUSED:
        case JobEventType.JOB_PAUSE_REQUESTED:
        case JobEventType.ACTION_PAUSED:
        case JobEventType.ACTION_PAUSE_REQUESTED: {
          return pausedIcon;
        }
        case JobEventType.JOB_WAITING_FOR_ACTOR:
        case JobEventType.JOB_WAITING_FOR_ACTION_START:
        case JobEventType.JOB_WAITING_FOR_REVOCATION_GROUP:
        case JobEventType.ACTION_REVOKED: {
          return props.nextEvent ? onHoldIcon : onHoldActiveIcon;
        }
        case JobEventType.JOB_SPAWNED_CHILD: {
          return spawnedIcon;
        }
        case JobEventType.ACTION_ERRORED:
        case JobEventType.ACTION_RETRY_FAILED:
        case JobEventType.JOB_UNSCHEDULE_FAILED: {
          return erroredIcon;
        }
        case JobEventType.ACTION_RETRIED:
        case JobEventType.ACTION_RETRY_REQUESTED: {
          return retryIcon;
        }
        case JobEventType.ACTION_CANCELLED:
        case JobEventType.JOB_CANCELLED:
        case JobEventType.JOB_CANCEL_REQUESTED:
        case JobEventType.JOB_UNSCHEDULE_COMPLETED: {
          return cancelledIcon;
        }
        case JobEventType.ACTION_RESUMED:
        case JobEventType.JOB_RESUMED: {
          return resumedIcon;
        }
        case JobEventType.ASSISTANCE_NEEDED: {
          return assistantIcon;
        }
        case JobEventType.ACTION_MANUAL_OVERRIDE:
        case JobEventType.ASSISTANT_SKIPPED: {
          return skippedIcon;
        }
        case JobEventType.ASSISTANT_STEP_SKIPPED:
        case JobEventType.ASSISTANT_TASK_SKIPPED: {
          return skippedStepIcon;
        }
        case JobEventType.ACTION_FILE_CAPTURE_INITIATED: {
          return runningIcon;
        }
        case JobEventType.ACTION_FILE_CAPTURED: {
          return fileIcon;
        }
        case JobEventType.GENERAL_ERROR: {
          const severity = props.event.details?.publicError?.severity;
          if (
            severity === PublicErrorSeverity.CRITICAL ||
            severity === PublicErrorSeverity.ERROR
          ) {
            return erroredIcon;
          } else {
            return infoIcon;
          }
        }
      }
    });

    const eventHasFile = computed(() => {
      return (
        props.event.type === JobEventType.ACTION_FILE_CAPTURED &&
        props.event.details?.storagePath
      );
    });

    const eventHasChildWithFile = computed(() => {
      return (
        props.event.type === JobEventType.PLACEHOLDER &&
        props.event.children?.some(
          (c) => c.type === JobEventType.ACTION_FILE_CAPTURED
        )
      );
    });

    const eventHasJobLink = computed(
      () =>
        props.event.type === JobEventType.JOB_SPAWNED_CHILD ||
        (props.event.initiatorId &&
          props.event.initiatorType ===
            EventSourceType.EVENT_SOURCE_TYPE_EXPRESSION_SERVICE)
    );

    const eventHasDisplayableDetails = computed(() => {
      return (
        (props.event.type === JobEventType.PLACEHOLDER &&
          (props.event.inputValues ||
            props.event.outputValues ||
            eventHasChildWithFile.value)) ||
        (WaitTypeEvents.includes(props.event.type) && props.event.details)
      );
    });

    const downloadFile = (event: JobEvent) => {
      console.log('Downloading ', event.details.storagePath);
      const url = `${process.env.VUE_APP_BASE_URL}/results-service/results/${event.details.fileId}`;
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
      return;
    };

    const openActionDetailsDialog = () => {
      emit('open-details', props.event);
    };

    const copyActionId = () => {
      navigator.clipboard.writeText(props.event.actionId);
    };

    const eventHasChildren = computed(() => props.event.children?.length);

    const childrenVisible = ref(false);

    const toggleExpandChildren = () => {
      childrenVisible.value = !childrenVisible.value;
    };

    const goToJob = () => {
      const spawnedJobId = props.event.details?.childJobId;
      if (spawnedJobId) {
        const labId =
          props.event.details?.childJobLabId ||
          OpsController.Instance.getJob(spawnedJobId).labId ||
          props.event.labId;
        router.push({
          ...route,
          params: { ...route.params, labId, jobId: spawnedJobId },
        });
      } else if (
        props.event.initiatorId &&
        props.event.initiatorType ===
          EventSourceType.EVENT_SOURCE_TYPE_EXPRESSION_SERVICE
      ) {
        const labId =
          props.event.details?.parentJobLabId ||
          OpsController.Instance.getJob(props.event.initiatorId).labId ||
          props.event.labId;
        router.push({
          ...route,
          params: { ...route.params, labId, jobId: props.event.initiatorId },
        });
      }
    };

    const handleTimelineItemClicked = () => {
      if (eventHasChildren.value) {
        toggleExpandChildren();
      } else if (eventHasJobLink.value) {
        goToJob();
      }
    };

    const itemContainsBracketingEvents = computed(() => {
      if (props.event.children?.length) {
        const eventTypes = props.event.children.map((c) => c.type);
        if (eventTypes.includes(JobEventType.ACTION_PAUSED)) {
          return eventTypes.includes(JobEventType.ACTION_RESUMED);
        }
      }
      return true;
    });

    const displayHint = computed(() => {
      // only show the display hint if the event has children
      // and the children contain a terminal event type
      // and the children contain an event type that requires a hint
      // and if the event has a bracketing event type (e.g. ACTION_PAUSED -> ACTION_RESUMED)
      if (props.event.children?.length) {
        const eventTypes = props.event.children.map((c) => c.type);
        return (
          eventTypes.some((t) => eventTypesRequiringHint.includes(t)) &&
          eventTypes.some((t) => TerminalEventTypes.includes(t)) &&
          itemContainsBracketingEvents.value
        );
      }
      return false;
    });

    const hintClass = computed(() => {
      if (props.event.children?.length) {
        const eventTypes = props.event.children.map((c) => c.type);
        if (eventTypes.includes(JobEventType.ACTION_RETRY_REQUESTED)) {
          return 'error-hint';
        } else if (eventTypes.includes(JobEventType.ACTION_PAUSED)) {
          return 'pause-hint';
        }
      }
      return '';
    });

    const hintText = computed(() => {
      if (hintClass.value === 'error-hint') {
        const retryAttempts =
          props.event.children?.filter(
            (c) => c.type === JobEventType.ACTION_RETRY_REQUESTED
          ).length || 0;
        return `${retryAttempts} ${
          retryAttempts > 1 ? 'retries' : 'retry'
        } after error`;
      } else if (hintClass.value === 'pause-hint') {
        const resumed =
          props.event.children?.filter(
            (c) => c.type === JobEventType.ACTION_RESUMED
          ) || [];
        const paused =
          props.event.children?.filter(
            (c) => c.type === JobEventType.ACTION_PAUSED
          ) || [];
        if (resumed.length && paused.length) {
          const sr = sortBy(resumed, 'timestamp').reverse();
          const sp = sortBy(paused, 'timestamp');
          const start = moment(sp[0].timestamp);
          const end = moment(sr[0].timestamp);
          const duration = moment.duration(end.diff(start));
          return `Paused for ${duration.humanize()}`;
        }
      }
    });

    const isAssistantStep = computed(
      () =>
        props.event.actionType === ActionType.ASSISTANT_STEP ||
        props.event.actionType === ActionType.ASSISTANT_TASK
    );

    const stepName = computed(() => {
      const name = props.event.details?.common?.name ?? '';
      const type =
        props.event.actionType === ActionType.ASSISTANT_TASK ? 'Task' : 'Step';
      return name ?? type;
    });

    watch(
      () => props.expandByDefault,
      () => {
        if (props.expandByDefault) {
          childrenVisible.value = true;
        }
      },
      { immediate: true }
    );

    return {
      expert,
      isArtificialUser,
      eventIcon,
      eventText,
      eventSourceText,
      eventTimestamp,
      eventHasFile,
      eventHasChildWithFile,
      eventHasJobLink,
      eventHasDisplayableDetails,
      downloadFile,
      openActionDetailsDialog,
      copyActionId,
      eventHasChildren,
      childrenVisible,
      handleTimelineItemClicked,
      goToJob,
      displayHint,
      hintClass,
      hintText,
      isAssistant,
      isAssistantStep,
      stepName,
      JobEventType,
    };
  },
});
