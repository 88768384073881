import { gql } from '@apollo/client/core';
import UIController from '@/clients/ui';
import { Action, WorkflowTreeNode, nullAction } from './model';
import { apollo } from '../service.apollo';
import {
  DASHBOARD_ASSISTANTS_QUERY,
  WORKFLOW_FIELDS,
  DOCUMENT_WORKFLOW_FIELDS,
} from './queries';
import { ClientWriterId } from '..';
import { LATEST_ASSISTANT_VERSION } from './version';

// ASSISTANTAPI STUFF
export async function getAssistants(): Promise<Action[]> {
  const result = await apollo.query<{ assistants: Action[] }>({
    query: DASHBOARD_ASSISTANTS_QUERY,
    fetchPolicy: 'cache-first',
  });

  return result.data.assistants.map((a) => ({
    ...nullAction(),
    ...a,
  }));
}

export async function createAssistant(
  name: string,
  labId?: string,
  compliant?: boolean
): Promise<Action> {
  const result = await apollo.mutate({
    variables: {
      name,
      labId,
      version: LATEST_ASSISTANT_VERSION,
      compliant: compliant || false,
    },
    mutation: gql`
      mutation CreateAssistant2(
        $name: String!
        $version: String!
        $labId: String
        $compliant: Boolean
      ) {
        createAssistant2(
          name: $name
          version: $version
          labId: $labId
          compliant: $compliant
        ) {
          id
          name
          actionType
          common {
            createdTimestamp
            modifiedTimestamp
            createdBy
          }
          constraint {
            labId
          }
          assistant {
            version
            compliant
          }
        }
      }
    `,
  });

  return result.data.createAssistant2;
}

export async function createDocumentWorkflow(
  name: string,
  labId: string
): Promise<Action> {
  const result = await apollo.mutate({
    variables: { name, labId, version: '0.0.1' },
    mutation: gql`
      mutation CreateDocumentWorkflow(
        $name: String!
        $version: String!
        $labId: ID!
      ) {
        createDocumentWorkflow(name: $name, version: $version, labId: $labId) {
          id
          name
          actionType
          common {
            createdTimestamp
            modifiedTimestamp
            createdBy
          }
          constraint {
            labId
          }
        }
      }
    `,
  });

  return result.data.createDocumentWorkflow;
}

export async function renameAssistant(
  assistantId: string,
  name: string
): Promise<Action> {
  const stepId = assistantId;
  const result = await apollo.mutate({
    variables: {
      assistantId,
      stepId,
      name,
    },
    mutation: gql`
      mutation RenameStep($assistantId: ID!, $stepId: ID!, $name: String!) {
        renameStep(assistantId: $assistantId, stepId: $stepId, name: $name) {
          id
          name
          common {
            revision
            modifiedTimestamp
          }
        }
      }
    `,
  });

  if (result?.data) {
    return result.data.renameStep;
  }
  return nullAction();
}

export async function setAssistantCompliance(
  assistantId: string,
  compliant: boolean
): Promise<Action> {
  const result = await apollo.mutate({
    variables: { assistantId, compliant },
    mutation: gql`
      mutation SetAssistantCompliance($assistantId: ID!, $compliant: Boolean!) {
        setAssistantCompliance(
          assistantId: $assistantId
          compliant: $compliant
        ) {
          id
          name
          common {
            revision
            modifiedTimestamp
          }
          assistant {
            compliant
          }
        }
      }
    `,
  });

  if (result?.data) {
    return result.data.setAssistantCompliance;
  }
  return nullAction();
}

export async function deleteAssistant(assistantId: string): Promise<void> {
  await apollo.mutate({
    variables: { assistantId },
    mutation: gql`
      mutation DeleteAssistant($assistantId: ID!) {
        deleteAssistant(assistantId: $assistantId)
      }
    `,
  });

  return Promise.resolve();
}

export async function deleteWorkflow(workflowId: string): Promise<void> {
  await apollo.mutate({
    variables: { id: workflowId },
    mutation: gql`
      mutation DeleteWorkflow($id: ID!) {
        deleteAction(id: $id)
      }
    `,
  });
  return Promise.resolve();
}

export async function getWorkflowTree(
  workflowId: string
): Promise<WorkflowTreeNode[]> {
  const result = await apollo.query<{ workflowTree: string }>({
    variables: {
      rootActionId: workflowId,
    },
    query: gql`
      query WorkflowTree($rootActionId: ID!) {
        workflowTree(rootActionId: $rootActionId)
      }
    `,
  });
  try {
    const tree = JSON.parse(result.data.workflowTree) as WorkflowTreeNode[];
    return tree;
  } catch (_) {
    return [];
  }
}

// APOLLO STUFF
export async function getWorkflows(): Promise<Action[]> {
  const query = UIController.Instance.hasFeatureFlag('DOCUMENT_WORKFLOW')
    ? gql`
        query GetWorkflows {
          workflows {
            ...DocumentWorkflowFields
          }
        }
        ${DOCUMENT_WORKFLOW_FIELDS}
      `
    : gql`
        query GetWorkflows {
          workflows {
            ...WorkflowFields
          }
        }
        ${WORKFLOW_FIELDS}
      `;
  const result = await apollo.query<{ workflows: Action[] }>({
    // TODO: I think cache-first is fine here. Workflows are static
    fetchPolicy: 'cache-first',
    query,
  });

  return result.data.workflows;
}

export async function getAction(id: string): Promise<Action> {
  const result = await apollo.query<{ action: Action }>({
    variables: { id },
    query: gql`
      query Action($id: ID!) {
        action(id: $id) {
          jobId
          name
          constraint {
            labId
          }
          common {
            createdBy
            createdTimestamp
            modifiedTimestamp
            revision
          }
        }
      }
    `,
  });

  return result.data.action;
}

export async function getBareAssistant(id: string): Promise<Action> {
  const result = await apollo.query<{ action: Action }>({
    variables: { id },
    query: gql`
      query Action($id: ID!) {
        action(id: $id) {
          jobId
        }
      }
    `,
  });
  return {
    ...nullAction(),
    id,
    jobId: result.data.action.jobId,
  };
}

export function startWorkflowSubscription(
  cb: (
    full: boolean,
    action: 'ADDED' | 'REMOVED' | 'UPDATED',
    workflows: Action[]
  ) => void
) {
  interface SubscriptionMessage {
    action: 'ADDED' | 'REMOVED' | 'UPDATED';
    full: boolean;
    workflows: Action[];
  }

  const query = UIController.Instance.hasFeatureFlag('DOCUMENT_WORKFLOW')
    ? gql`
        subscription SubscribeWorkflows($clientId: ID!) {
          workflowsSub(clientId: $clientId) {
            full
            action
            workflows {
              ...DocumentWorkflowFields
            }
          }
        }
        ${DOCUMENT_WORKFLOW_FIELDS}
      `
    : gql`
        subscription SubscribeWorkflows($clientId: ID!) {
          workflowsSub(clientId: $clientId) {
            full
            action
            workflows {
              ...WorkflowFields
            }
          }
        }
        ${WORKFLOW_FIELDS}
      `;
  const subscription = apollo
    .subscribe<{ workflowsSub: SubscriptionMessage }>({
      variables: { clientId: ClientWriterId },
      query,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.workflowsSub;
        if (response) {
          cb(response.full, response.action, response.workflows || []);
        }
      },
      error: console.error,
    });

  return () => subscription.unsubscribe();
}
