import { gql, DocumentNode } from '@apollo/client/core';
import unescape from 'validator/es/lib/unescape';
import { v4 as uuid } from 'uuid';
import {
  Job,
  Lab,
  ActionCommandMessage,
  JobState,
  nullLab,
  nullJob,
  JobValidationResponse,
  File,
  PublicError,
  ScheduleResponse,
  PreviewJobResponse,
  SchedulingType,
  JobFilters,
  JobTableData,
  LastFailedActionInfo,
  JobEventQueryResponse,
  mapFromBackend,
  JobEventResponse,
  UserJobDocumentResponse,
  JobEvent,
  JobEventBackend,
  JobEventType,
  GanttJob,
  HeartbeatSubscription,
  GanttRequest,
  GanttSetActivityOffsetType,
  GanttSetActivityDurationType,
  GanttRescheduleActorAssignmentType,
  GanttUpdateActorOfflineStateType,
} from './model';
import { ClientWriterId } from '..';
import { apollo } from '../service.apollo';
import { Asset, SimplifiedState } from '../model';
import {
  DASHBOARD_LABS_QUERY,
  JOB_DETAILS_QUERY,
  LEGACY_JOB_DETAILS_QUERY,
  JOB_CARD_QUERY,
  THIN_JOB_DETAILS_W_IO_QUERY,
  THIN_JOB_DETAILS_W_IO_FIELDS,
  OPS_QUERY,
  SIMPLE_LAB_QUERY,
  OPS_SUBSCRIPTION_QUERY,
  NEW_JOB_MUTATION,
  EXPORT_LABS_QUERY,
  RESULTS_LABS_QUERY,
  JOB_FILES_QUERY,
  SETTINGS_LAB_QUERY,
  SCHEDULE_RESPONSE_FIELDS,
  PUBLIC_ERROR_FIELDS,
  OPS_TABLE_QUERY,
  JOB_EVENT_FIELDS,
  LAB_QUERY,
} from './queries';
import {
  InteractivityHookStage,
  SimpleResponse,
} from '@/components/GenericForm/types';
import { Action } from '../action';
import { ActionType, nullAction } from '../action/model';

function unescapeJob(input: Job): Job {
  if (input.name) {
    return {
      ...input,
      name: unescape(input.name || ''),
    };
  } else {
    return input;
  }
}

function unescapeLab(input: Lab): Lab {
  return {
    ...input,
    name: unescape(input.name || ''),
    location: unescape(input.location || ''),
  };
}

export async function batchJobs(jobIds: string[]): Promise<Job> {
  const result = await apollo.mutate<{ batchJobs: Job }>({
    variables: { jobIds: jobIds },
    mutation: gql`
      mutation BatchJobs($jobIds: [String]) {
        batchJobs(jobIds: $jobIds) {
          ...ThinJobDetailsFieldsWithIo
        }
      }
      ${THIN_JOB_DETAILS_W_IO_FIELDS}
    `,
  });

  if (result && result.data) {
    return unescapeJob(result.data.batchJobs);
  }
  throw `Failed to batch jobs: [${jobIds.join(', ')}]`;
}

export async function unbatchJob(jobId: string): Promise<string> {
  const result = await apollo.mutate<{ unbatchJob: string }>({
    variables: { jobId: jobId },
    mutation: gql`
      mutation UnbatchJob($jobId: String) {
        unbatchJob(jobId: $jobId)
      }
    `,
  });

  if (result.data?.unbatchJob) {
    return result.data.unbatchJob;
  }
  throw `Failed to unbatch job ${jobId}`;
}

export async function deleteJob(jobId: string): Promise<string> {
  const result = await apollo.mutate<{ deleteJob: string }>({
    variables: { id: jobId },
    mutation: gql`
      mutation DeleteJob($id: ID!) {
        deleteJob(id: $id)
      }
    `,
  });

  if (result && result.data) {
    return result.data.deleteJob;
  }

  throw new Error('Invalid operation; cannot delete job');
}

export async function batchDeleteJobs(jobIds: string[]): Promise<string[]> {
  const result = await apollo.mutate<{ batchDeleteJobs: string[] }>({
    variables: { jobIds: jobIds },
    mutation: gql`
      mutation BatchDeleteJobs($jobIds: [ID]!) {
        batchDeleteJobs(jobIds: $jobIds)
      }
    `,
  });

  if (result && result.data) {
    return result.data.batchDeleteJobs;
  }

  throw new Error('Invalid operation; cannot delete jobs');
}

export async function deleteJobs(labId: string): Promise<string[]> {
  const result = await apollo.mutate<{ deleteJobs: string[] }>({
    variables: { labId: labId },
    mutation: gql`
      mutation DeleteJobs($labId: ID!) {
        deleteJobs(labId: $labId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.deleteJobs;
  }

  throw new Error('Invalid operation; cannot delete jobs');
}

export async function newJob(
  name: string,
  labId: string,
  actionId: string,
  file?: string,
  actionsToCancel?: string[],
  parameterValues?: string | null
): Promise<Job> {
  let variables: Record<string, string | string[]> = {
    name,
    labId,
    actionId,
    clientId: ClientWriterId,
  };

  if (actionsToCancel && actionsToCancel.length > 0) {
    variables = { ...variables, actionsToCancel };
  }
  if (file) {
    variables = { ...variables, file };
  }
  if (parameterValues) {
    variables = { ...variables, parameterValues };
  }
  const result = await apollo.mutate<{ newJob: Job }>({
    variables,
    mutation: NEW_JOB_MUTATION,
  });

  if (result && result.data) {
    if (!result.data.newJob.workflowId) {
      result.data.newJob.workflowId = actionId;
    }
    return unescapeJob(result.data.newJob);
  }

  throw new Error('Invalid operation; cannot create job');
}

export async function getLabsForDashboard(): Promise<Lab[]> {
  const result = await apollo.query<{ labs: Lab[] }>({
    query: DASHBOARD_LABS_QUERY,
  });

  if (result && result.data) {
    return result.data.labs.map((l) => ({
      ...nullLab(),
      ...unescapeLab(l),
    }));
  }
  throw new Error(
    `Cannot query for labs: ${result.error?.message || 'unknown error'}`
  );
}

export async function updateLab(lab: Lab): Promise<Lab> {
  const labInput = {
    id: lab.id,
    name: lab.name,
    location: lab.location,
    common: {
      revision: lab.common.revision,
    },
  };
  const result = await apollo.mutate<{ updateLab: Lab }>({
    variables: { lab: labInput },
    mutation: gql`
      mutation UpdateLab($lab: LabInput!) {
        updateLab(lab: $lab) {
          id
          name
          location
          common {
            revision
            modifiedTimestamp
          }
        }
      }
    `,
  });

  if (result?.data) {
    return unescapeLab(result.data.updateLab);
  }
  return nullLab();
}

export async function getAssetsByLab(labId: string): Promise<Asset[]> {
  const result = await apollo.query<{ assetsByLab: Asset[] }>({
    variables: { labId },
    query: gql`
      query AssetsByLab($labId: ID!) {
        assetsByLab(labId: $labId) {
          id
          name
          definitionId
        }
      }
    `,
  });

  return result.data.assetsByLab.map((a) => ({
    id: a.id,
    name: a.name,
    labId,
    parentId: '',
    definitionId: a.definitionId,
    common: {
      revision: '',
    },
  }));
}

export async function getJobForJobDetails(
  jobId: string,
  legacy = false
): Promise<Job> {
  const result = await apollo.query<{ job: Job }>({
    query: legacy ? LEGACY_JOB_DETAILS_QUERY : JOB_DETAILS_QUERY,
    variables: { id: jobId },
  });

  if (result && result.data) {
    return unescapeJob({
      ...nullJob(),
      ...result.data.job,
    });
  }
  throw new Error(
    `Cannot query job ${jobId}: ${result.error?.message || 'unknown error'}`
  );
}

export async function getJobEvents(
  jobId: string,
  offset: number,
  limit: number,
  eventTypes: JobEventType[],
  actionTypes: ActionType[]
): Promise<JobEventResponse> {
  const result = await apollo.query<{ jobEvents: JobEventQueryResponse }>({
    variables: {
      offset,
      limit,
      orderBy: ['desc', 'redisTimestamp'],
      filters: {
        jobIds: [jobId],
        eventTypes,
        actionTypes,
      },
    },
    context: { ignoreLongCalls: true },
    query: gql`
      query GetJobEvents(
        $offset: Int
        $limit: Int
        $orderBy: [String]
        $filters: JobEventFilters
      ) {
        jobEvents(
          offset: $offset
          limit: $limit
          orderBy: $orderBy
          filters: $filters
        ) {
          jobEvents {
            ...JobEventFields
          }
          limit
          offset
          totalCount
        }
      }
      ${JOB_EVENT_FIELDS}
    `,
  });
  if (result.data?.jobEvents) {
    return {
      limit: result.data.jobEvents.limit,
      offset: result.data.jobEvents.offset,
      totalCount: result.data.jobEvents.totalCount,
      jobEvents: mapFromBackend(result.data.jobEvents.jobEvents),
    };
  }

  throw new Error(`Failed to retrieve job event stream`);
}

export async function getErroredJobEvents(
  jobId: string,
  offset: number,
  limit: number,
  eventTypes: JobEventType[],
  actionTypes: ActionType[]
): Promise<JobEventResponse> {
  const result = await apollo.query<{
    erroredActionJobEvents: JobEventQueryResponse;
  }>({
    variables: {
      offset,
      limit,
      filters: {
        jobId,
        eventTypes,
        actionTypes,
      },
    },
    context: { ignoreLongCalls: true },
    query: gql`
      query GetErroredJobEvents(
        $offset: Int
        $limit: Int
        $filters: ErroredActionJobEventFilters
      ) {
        erroredActionJobEvents(
          offset: $offset
          limit: $limit
          filters: $filters
        ) {
          jobEvents {
            ...JobEventFields
          }
          limit
          offset
          totalCount
        }
      }
      ${JOB_EVENT_FIELDS}
    `,
  });
  if (result.data?.erroredActionJobEvents) {
    return {
      limit: result.data.erroredActionJobEvents.limit,
      offset: result.data.erroredActionJobEvents.offset,
      totalCount: result.data.erroredActionJobEvents.totalCount,
      jobEvents: mapFromBackend(result.data.erroredActionJobEvents.jobEvents),
    };
  }

  throw new Error(`Failed to retrieve job event stream`);
}

export async function getJobParameterEvents(
  jobId: string
): Promise<JobEventResponse> {
  const result = await apollo.query<{ jobEvents: JobEventQueryResponse }>({
    variables: {
      offset: 0,
      noLimit: true,
      orderBy: ['desc', 'redisTimestamp'],
      filters: {
        jobIds: [jobId],
        eventTypes: [
          JobEventType.JOB_PARAMETERS_APPLIED,
          JobEventType.JOB_CANCELLED,
          JobEventType.JOB_OUTPUTS_CREATED,
        ],
        actionTypes: [ActionType.UNKNOWN],
      },
    },
    context: { ignoreLongCalls: true },
    query: gql`
      query GetJobParameterEvents(
        $offset: Int
        $noLimit: Boolean
        $orderBy: [String]
        $filters: JobEventFilters
      ) {
        jobEvents(
          offset: $offset
          noLimit: $noLimit
          orderBy: $orderBy
          filters: $filters
        ) {
          jobEvents {
            ...JobEventFields
          }
          totalCount
        }
      }
      ${JOB_EVENT_FIELDS}
    `,
  });
  if (result.data?.jobEvents) {
    return {
      limit: 0,
      offset: 0,
      totalCount: result.data.jobEvents.totalCount,
      jobEvents: mapFromBackend(result.data.jobEvents.jobEvents),
    };
  }

  throw new Error(`Failed to retrieve job event stream`);
}

export async function getJobReport(jobId: string): Promise<unknown> {
  const result = await apollo.query<{
    userJobDocument: UserJobDocumentResponse;
  }>({
    query: gql`
      query UserJobDocument($jobId: ID!) {
        userJobDocument(jobId: $jobId) {
          document
        }
      }
    `,
    variables: { jobId },
  });
  if (result?.data) {
    return JSON.parse(result.data.userJobDocument.document);
  }
  return null;
}

export async function getJobForJobCard(jobId: string): Promise<Job> {
  const result = await apollo.query<{ job: Job }>({
    query: JOB_CARD_QUERY,
    variables: { id: jobId },
  });

  if (result && result.data) {
    return unescapeJob({
      ...nullJob(),
      ...result.data.job,
    });
  }
  throw new Error(
    `Cannot query job ${jobId}: ${result.error?.message || 'unknown error'}`
  );
}

export async function getJobForScheduleDialog(jobId: string): Promise<Job> {
  const result = await apollo.query<{ job: Job }>({
    query: THIN_JOB_DETAILS_W_IO_QUERY,
    variables: { id: jobId },
  });

  if (result && result.data) {
    return unescapeJob({
      ...nullJob(),
      ...result.data.job,
    });
  }
  throw new Error(
    `Cannot query job ${jobId}: ${result.error?.message || 'unknown error'}`
  );
}

export async function getLabForJobDetails(labId: string): Promise<Lab> {
  const result = await apollo.query<{ lab: Lab }>({
    query: SIMPLE_LAB_QUERY,
    variables: { id: labId },
  });

  if (result && result.data) {
    return unescapeLab({
      ...nullLab(),
      ...result.data.lab,
    });
  }

  throw new Error(
    `Cannot query lab ${labId}: ${result.error?.message || 'unknown error'}`
  );
}

export async function getLabForOps(labId: string): Promise<Lab> {
  const result = await apollo.query<{ lab: Lab }>({
    query: OPS_QUERY,
    variables: { id: labId },
    fetchPolicy: 'no-cache',
  });

  if (result && result.data) {
    return unescapeLab({
      ...nullLab(),
      ...result.data.lab,
    });
  }

  throw new Error(
    `Cannot query lab ${labId}: ${result.error?.message || 'unknown error'}`
  );
}

export async function getJobsByPage(
  offset: number,
  limit: number,
  filters: JobFilters,
  orderBy: string[]
): Promise<JobTableData> {
  interface JobsVariables {
    offset: number;
    filters: JobFilters;
    orderBy: string[];
    limit?: number;
    noLimit?: boolean;
  }
  const variables: JobsVariables = {
    offset,
    filters,
    orderBy,
  };
  if (limit < 0) {
    variables.noLimit = true;
  } else {
    variables.limit = limit;
  }
  const result = await apollo.query<{ jobs: JobTableData }>({
    query: OPS_TABLE_QUERY,
    variables,
    context: { ignoreLongCalls: true },
  });
  if (result.data?.jobs) {
    result.data.jobs.jobs = result.data.jobs.jobs.map((j) => unescapeJob(j));
    return result.data.jobs;
  }
  return {
    jobs: [],
    offset: 0,
    limit: 0,
    totalCount: 0,
  };
}

export async function getRandomGanttJobs(howMany: number): Promise<GanttJob[]> {
  const result = await apollo.query<{ randomGanttJobs: GanttJob[] }>({
    context: { ignoreLongCalls: true },
    query: gql`
      query RandomGanttJobs($howMany: Int!) {
        randomGanttJobs(howMany: $howMany) {
          name
          id
          workflowId
          start
          activities {
            name
            start
            end
            state
            actionId
            actionType
          }
          annotations {
            type
            timestamp
          }
        }
      }
    `,
    variables: { howMany },
  });
  return result.data.randomGanttJobs;
}

export async function getGanttJobs(
  labId: string,
  completedAfter: string,
  startedBefore: string,
  limit: number,
  offset: number
): Promise<GanttJob[]> {
  const result = await apollo.query<{ ganttJobs: GanttJob[] }>({
    context: { ignoreLongCalls: true },
    query: gql`
      query GanttJobs(
        $labId: String!
        $completedAfter: DateTime!
        $startedBefore: DateTime!
        $limit: Int!
        $offset: Int!
      ) {
        ganttJobs(
          labId: $labId
          completedAfter: $completedAfter
          startedBefore: $startedBefore
          limit: $limit
          offset: $offset
        ) {
          name
          id
          workflowId
          start
          activities {
            name
            start
            end
            state
            actionId
            actionType
          }
          annotations {
            type
            timestamp
          }
        }
      }
    `,
    variables: { labId, completedAfter, startedBefore, limit, offset },
  });
  return result.data.ganttJobs;
}

// the new gantt data endpoint from Watson
export async function getGanttJobsEx(
  labId: string,
  completedAfter: string,
  startedBefore: string,
  limit: number,
  offset: number
): Promise<GanttJob[]> {
  const result = await apollo.query<{ ganttJobsEx: GanttJob[] }>({
    context: { ignoreLongCalls: true },
    query: gql`
      query GanttJobsEx(
        $labId: ID!
        $completedAfter: String!
        $startedBefore: String!
        $limit: Int!
        $offset: Int!
      ) {
        ganttJobsEx(
          labId: $labId
          completedAfter: $completedAfter
          startedBefore: $startedBefore
          limit: $limit
          offset: $offset
        ) {
          name
          id
          workflowId
          start
          end
          activities {
            name
            start
            end
            state
            actionId
            actionType
          }
          annotations {
            type
            timestamp
          }
        }
      }
    `,
    variables: { labId, completedAfter, startedBefore, limit, offset },
  });
  return result.data.ganttJobsEx;
}

// the new gantt data endpoint from Watson that includes projected data
export async function getGanttJobsWithProjections(
  labId: string,
  completedAfter: string,
  startedBefore: string,
  includeProjections: boolean,
  limit: number,
  offset: number
): Promise<GanttRequest> {
  const result = await apollo.query<{ ganttJobsWithProjections: GanttRequest }>(
    {
      context: { ignoreLongCalls: true },
      query: gql`
        query GanttJobsWithProjections(
          $labId: ID!
          $completedAfter: String!
          $startedBefore: String!
          $includeProjections: Boolean
          $limit: Int!
          $offset: Int!
        ) {
          ganttJobsWithProjections(
            labId: $labId
            completedAfter: $completedAfter
            startedBefore: $startedBefore
            includeProjections: $includeProjections
            limit: $limit
            offset: $offset
          ) {
            projectionsComputedAt
            jobs {
              name
              id
              workflowId
              start
              end
              activities {
                name
                id
                start
                end
                state
                actionId
                actionType
                compatibleActorIds
                actorRequestIndex
                scheduledActor {
                  actorId
                  deviceId
                }
                constraints {
                  defaultDuration
                  relativeOffset
                  rescheduledActorId
                }
                projected
              }
              annotations {
                id
                type
                timestamp
              }
            }
            actorStatuses {
              actorId
              offline
            }
          }
        }
      `,
      variables: {
        labId,
        completedAfter,
        startedBefore,
        includeProjections,
        limit,
        offset,
      },
    }
  );
  return result.data.ganttJobsWithProjections;
}

export async function ganttSetActivityOffset(
  params: GanttSetActivityOffsetType
): Promise<boolean> {
  const result = await apollo.mutate<{ ganttSetActivityOffset: boolean }>({
    variables: { ...params },
    mutation: gql`
      mutation GanttSetActivityOffset(
        $jobId: ID!
        $activityId: ID!
        $relativeOffset: Float
      ) {
        ganttSetActivityOffset(
          jobId: $jobId
          activityId: $activityId
          relativeOffset: $relativeOffset
        )
      }
    `,
  });

  if (result && result.data) {
    return result.data.ganttSetActivityOffset;
  }

  throw new Error('Error in ganttSetActivityOffset');
}

export async function ganttSetActivityDuration(
  params: GanttSetActivityDurationType
): Promise<boolean> {
  const result = await apollo.mutate<{ ganttSetActivityDuration: boolean }>({
    variables: { ...params },
    mutation: gql`
      mutation GanttSetActivityDuration(
        $jobId: ID!
        $activityId: ID!
        $duration: Float
      ) {
        ganttSetActivityDuration(
          jobId: $jobId
          activityId: $activityId
          duration: $duration
        )
      }
    `,
  });

  if (result && result.data) {
    return result.data.ganttSetActivityDuration;
  }

  throw new Error('Error in ganttSetActivityDuration');
}

export async function ganttRescheduleActorAssignment(
  params: GanttRescheduleActorAssignmentType
): Promise<boolean> {
  const result = await apollo.mutate<{
    ganttRescheduleActorAssignment: boolean;
  }>({
    variables: { ...params },
    mutation: gql`
      mutation GanttRescheduleActorAssignment(
        $labId: ID!
        $jobId: ID!
        $actorId: ID!
        $actorRequestIndex: Int!
      ) {
        ganttRescheduleActorAssignment(
          labId: $labId
          jobId: $jobId
          actorId: $actorId
          actorRequestIndex: $actorRequestIndex
        )
      }
    `,
  });

  if (result && result.data) {
    return result.data.ganttRescheduleActorAssignment;
  }

  throw new Error('Error in ganttRescheduleActorAssignment');
}

export async function ganttUpdateActorOfflineState(
  params: GanttUpdateActorOfflineStateType
): Promise<boolean> {
  const result = await apollo.mutate<{
    updateActorOfflineState: boolean;
  }>({
    variables: { ...params },
    mutation: gql`
      mutation UpdateActorOfflineState(
        $labId: ID!
        $actorId: ID!
        $offline: Boolean!
        $serviceId: ID!
      ) {
        updateActorOfflineState(
          labId: $labId
          actorId: $actorId
          offline: $offline
          serviceId: $serviceId
        )
      }
    `,
  });

  if (result && result.data) {
    return result.data.updateActorOfflineState;
  }

  throw new Error('Error in ganttUpdateActorOfflineState');
}

export async function getFilteredJobsNoLimit(
  filters: JobFilters,
  orderBy: string[],
  query: DocumentNode
): Promise<JobTableData> {
  const result = await apollo.query<{ jobs: JobTableData }>({
    query,
    variables: {
      filters,
      orderBy,
    },
  });
  if (result.data?.jobs) {
    result.data.jobs.jobs = result.data.jobs.jobs.map((j) => unescapeJob(j));
    return result.data.jobs;
  }
  return {
    jobs: [],
    offset: 0,
    limit: 0,
    totalCount: 0,
  };
}

export async function getLabs(): Promise<Lab[]> {
  const result = await apollo.query<{ labs: Lab[] }>({
    query: EXPORT_LABS_QUERY,
  });

  return result.data.labs.map((l) => unescapeLab(l));
}

export async function getLabsForSettings(): Promise<Lab[]> {
  const result = await apollo.query<{ labs: Lab[] }>({
    query: SETTINGS_LAB_QUERY,
  });
  return result.data.labs.map((l) => unescapeLab(l));
}

export async function getLab(labId: string): Promise<Lab> {
  const result = await apollo.query<{ lab: Lab }>({
    variables: {
      id: labId,
    },
    query: LAB_QUERY,
    context: { ignoreLongCalls: true },
  });
  return unescapeLab(result.data.lab);
}

export async function getLabResults(): Promise<Lab[]> {
  const result = await apollo.query<{ labs: Lab[] }>({
    query: RESULTS_LABS_QUERY,
  });
  return result.data.labs.map((l) => unescapeLab(l));
}

export async function newLab(lab: Lab): Promise<Lab> {
  const result = await apollo.mutate<{ newLab: Lab }>({
    // TODO:  Come up with a better way to exclude fields not in LabInput.
    variables: { lab: { ...lab, jobs: undefined, state: undefined } },
    mutation: gql`
      mutation NewLab($lab: LabInput!) {
        newLab(lab: $lab) {
          id
          name
          description
          location
          label
          version
          jobs {
            id
          }
          common {
            createdBy
            createdTimestamp
            modifiedTimestamp
            revision
          }
        }
      }
    `,
  });

  if (result && result.data) {
    return unescapeLab(result.data.newLab);
  }

  throw new Error('Invalid operation; cannot create lab');
}

export async function deleteLab(id: string): Promise<string> {
  const result = await apollo.mutate<{ deleteLab: string }>({
    variables: { id },
    mutation: gql`
      mutation DeleteLab($id: ID!) {
        deleteLab(id: $id)
      }
    `,
  });

  if (result && result.data) {
    return result.data.deleteLab;
  }

  throw new Error('Invalid operation; cannot delete lab');
}

export async function getLabConnectionHealth(id: string): Promise<Lab> {
  const result = await apollo.query<{ lab: Lab }>({
    variables: { id },
    query: gql`
      query GetConnectionHealth($id: ID!) {
        lab(id: $id) {
          id
          adapterIds
          connections {
            client {
              name
              healthInfo {
                key
                value
              }
            }
            scope
          }
          bannedConnections
        }
      }
    `,
    context: { ignoreLongCalls: true },
  });

  if (result && result.data) {
    return result.data.lab;
  }
  throw new Error('Invalid operation; cannot get lab health connection info');
}

export async function setParameterValues(
  actionId: string,
  values: SimpleResponse
): Promise<boolean> {
  const strValues = JSON.stringify(values);
  const result = await apollo.mutate({
    variables: {
      actionId,
      values: strValues,
    },
    mutation: gql`
      mutation SetActionParameterValues($actionId: ID!, $values: String!) {
        setActionParameterValues(actionId: $actionId, values: $values) {
          id
        }
      }
    `,
  });
  if (!result.errors) {
    return true;
  }
  return false;
}

export async function getLastFailedActionId(
  id: string
): Promise<LastFailedActionInfo | null> {
  const response = await apollo.query<{ job: Job }>({
    variables: { id },
    query: gql`
      query GetLastFailedActionId($id: ID!) {
        job(id: $id) {
          lastFailedAction {
            actionId
            canSkip
            canRetry
            isAnonymous
          }
        }
      }
    `,
  });
  return response.data?.job?.lastFailedAction || null;
}

/**
 * DEPRECATED
 * @param labId the ID of the lab
 * @param actionId the ID of the action to skip
 * @returns Schedule info of rescheduled action
 */
export async function resetAndRescheduleAction(
  labId: string,
  actionId: string
): Promise<ScheduleResponse> {
  const response = await apollo.mutate<{
    resetAndRescheduleAction: ScheduleResponse;
  }>({
    variables: { labId, actionId },
    mutation: gql`
      mutation ResetAndRescheduleAction($labId: ID!, $actionId: ID!) {
        resetAndRescheduleAction(labId: $labId, actionId: $actionId) {
          scheduleId
          solutionInfo {
            solutionFound
          }
        }
      }
    `,
  });

  if (response?.data) {
    return response.data.resetAndRescheduleAction;
  }
  return {
    scheduleId: '',
    solutionInfo: {
      solutionFound: false,
    },
    errors: [],
  };
}

export async function retryAction(
  jobId: string,
  actionId: string
): Promise<string> {
  const response = await apollo.mutate<{ retryLastFailedAction: string }>({
    variables: { jobId, actionId },
    mutation: gql`
      mutation RetryLastFailedAction($jobId: ID!, $actionId: ID!) {
        retryLastFailedAction(jobId: $jobId, actionId: $actionId)
      }
    `,
  });

  return response.data?.retryLastFailedAction || '';
}

/** */
export async function skipAction(
  labId: string,
  actionId: string,
  outputData: string
) {
  const response = await apollo.mutate<{ skipAction: boolean }>({
    variables: { labId, actionId, outputData },
    mutation: gql`
      mutation SkipAction($labId: ID!, $actionId: ID!, $outputData: String) {
        skipAction(labId: $labId, actionId: $actionId, outputData: $outputData)
      }
    `,
  });
  return response.data?.skipAction || false;
}

export async function skipAction2(
  jobId: string,
  actionId: string,
  outputParameterValues: string
): Promise<string> {
  const response = await apollo.mutate<{ skipLastFailedAction: string }>({
    variables: { jobId, actionId, outputParameterValues },
    mutation: gql`
      mutation SkipLastFailedAction(
        $jobId: ID!
        $actionId: ID!
        $outputParameterValues: String!
      ) {
        skipLastFailedAction(
          jobId: $jobId
          actionId: $actionId
          outputParameterValues: $outputParameterValues
        )
      }
    `,
  });

  return response.data?.skipLastFailedAction || '';
}

export async function getFilesByJob(jobId): Promise<File[]> {
  interface FileResponse {
    limit: number;
    offset: number;
    files: File[];
  }

  const result = await apollo.query<{ fileQuery: FileResponse }>({
    variables: {
      jobId,
    },
    query: JOB_FILES_QUERY,
  });

  if (result && result.data) {
    return result.data.fileQuery.files;
  }
  return [];
}

interface PublicErrorResponse {
  publicErrors: PublicError[];
}

export async function getActionNamesByJob(jobId: string): Promise<Action[]> {
  const result = await apollo.query<{ job: Job }>({
    variables: {
      id: jobId,
    },
    query: gql`
      query GetActionNames($id: ID!) {
        job(id: $id) {
          actions {
            id
            name
            actionType
          }
        }
      }
    `,
  });

  if (result && result.data && result.data.job) {
    return result.data.job.actions || [];
  }
  return [];
}

export async function getErrorsByLab(
  labId: string,
  limit = 100,
  offset = 0
): Promise<PublicError[]> {
  const filters = { labId };
  const result = await apollo.query<{ publicErrorsQuery: PublicErrorResponse }>(
    {
      variables: {
        filters,
        limit,
        offset,
        orderBy: ['desc', 'timestamp'],
      },
      query: gql`
        query Errors(
          $filters: PublicErrorFilter
          $limit: Int
          $offset: Int
          $orderBy: [String]
        ) {
          publicErrorsQuery(
            filters: $filters
            limit: $limit
            offset: $offset
            orderBy: $orderBy
          ) {
            publicErrors {
              ...PublicErrorFields
            }
          }
        }
        ${PUBLIC_ERROR_FIELDS}
      `,
    }
  );
  return result.data.publicErrorsQuery.publicErrors ?? [];
}

export function startSubscribeErrors(
  labId: string,
  cb: (publicError: PublicError) => void
) {
  const subscription = apollo
    .subscribe<{ publicErrorsFilteredSubscription: PublicError }>({
      fetchPolicy: 'no-cache',
      variables: { filters: { labId } },
      query: gql`
        subscription Errors($filters: PublicErrorSubscriptionFilter) {
          publicErrorsFilteredSubscription(filters: $filters) {
            ...PublicErrorFields
          }
        }
        ${PUBLIC_ERROR_FIELDS}
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.publicErrorsFilteredSubscription;
        if (response) {
          cb(response);
        }
      },
      error: console.error,
    });
  return () => subscription.unsubscribe();
}

export function startSubscribeOps(
  labId: string,
  cb: (op: string, full: boolean, job: Job[]) => void
) {
  interface SubscriptionMessage {
    action: 'ADDED' | 'REMOVED' | 'UPDATED';
    full: boolean;
    jobs: Job[];
  }

  const subscription = apollo
    .subscribe<{ jobsInLab: SubscriptionMessage }>({
      fetchPolicy: 'no-cache',
      variables: { labId, clientId: ClientWriterId },
      query: OPS_SUBSCRIPTION_QUERY,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.jobsInLab;
        if (response) {
          cb(
            response.action,
            response.full,
            response.jobs.map((j) => unescapeJob(j))
          );
        }
      },
      error: console.error,
    });

  return () => subscription.unsubscribe();
}

export function startSubscribeState(
  labId: string,
  cb: (full: boolean, jobsState: JobState[]) => void
) {
  interface SubscriptionMessage {
    full: boolean;
    jobsState: JobState[];
  }

  const subscription = apollo
    .subscribe<{ jobsState: SubscriptionMessage }>({
      fetchPolicy: 'no-cache',
      variables: {
        labId,
        clientId: ClientWriterId,
      },
      query: gql`
        subscription JobsState($labId: ID!, $clientId: ID!) {
          jobsState(labId: $labId, clientId: $clientId) {
            full
            jobsState {
              job {
                id
                labId
                state
              }
              assistants {
                id
                state
                name
                actionType
                parentId
                isSkippableAssistant
                constraint {
                  labId
                }
                assistant {
                  compliant
                }
              }
            }
          }
        }
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.jobsState;
        if (response) {
          cb(response.full, response.jobsState);
        }
      },
      error: console.error,
    });

  return () => subscription.unsubscribe();
}

export function startSubscribeStateThin(
  labId: string,
  cb: (full: boolean, jobsState: JobState[]) => void
) {
  interface SubscriptionMessage {
    full: boolean;
    jobsState: JobState[];
  }

  const subscription = apollo
    .subscribe<{ jobsState: SubscriptionMessage }>({
      fetchPolicy: 'no-cache',
      variables: {
        labId,
        clientId: ClientWriterId,
      },
      query: gql`
        subscription JobsState($labId: ID!, $clientId: ID!) {
          jobsState(labId: $labId, clientId: $clientId) {
            full
            jobsState {
              job {
                id
                state
              }
            }
          }
        }
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.jobsState;
        if (response) {
          cb(response.full, response.jobsState);
        }
      },
      error: console.error,
    });

  return () => subscription.unsubscribe();
}

export async function sendSubscriptionHeartbeat(
  subscriptionId?: string
): Promise<void> {
  if (subscriptionId) {
    await apollo.query({
      variables: {
        subscriptionId,
      },
      context: { ignoreLongCalls: true },
      query: gql`
        query SubscriptionHeartbeat($subscriptionId: ID!) {
          subscriptionHeartbeat(subscriptionId: $subscriptionId)
        }
      `,
    });
  }
}

export function startSubscribeJobEvents(
  jobId: string,
  latestTimestamp: string,
  eventTypes: JobEventType[],
  actionTypes: ActionType[],
  cb: (event: JobEvent) => void
): HeartbeatSubscription {
  const subscriptionId = uuid();
  const subscription = apollo
    .subscribe<{
      jobEventsSubscription: JobEventBackend;
    }>({
      fetchPolicy: 'no-cache',
      variables: {
        jobId,
        eventTypes,
        actionTypes,
        eventsAfter: latestTimestamp,
        subscriptionId,
      },
      query: gql`
        subscription JobEventsSubscription(
          $jobId: ID!
          $eventTypes: [JobEventType]!
          $actionTypes: [ActionType]!
          $subscriptionId: ID!
          $eventsAfter: String
        ) {
          jobEventsSubscription(
            jobId: $jobId
            eventTypes: $eventTypes
            actionTypes: $actionTypes
            subscriptionId: $subscriptionId
            eventsAfter: $eventsAfter
          ) {
            ...JobEventFields
          }
        }
        ${JOB_EVENT_FIELDS}
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.jobEventsSubscription;
        if (response) {
          cb(mapFromBackend([response])[0]);
        }
      },
      error: console.error,
    });

  const heartbeatSubscription: HeartbeatSubscription = {
    subscriptionId,
    unsubscribeFn: () => subscription.unsubscribe(),
  };

  return heartbeatSubscription;
}

export function startSubscribeErroredJobEvents(
  jobId: string,
  latestTimestamp: string,
  eventTypes: JobEventType[],
  actionTypes: ActionType[],
  cb: (event: JobEvent) => void
): HeartbeatSubscription {
  const subscriptionId = uuid();
  const subscription = apollo
    .subscribe<{
      erroredActionJobEventsSubscription: JobEventBackend;
    }>({
      fetchPolicy: 'no-cache',
      variables: {
        jobId,
        eventTypes,
        actionTypes,
        subscriptionId,
        eventsAfter: latestTimestamp,
      },
      query: gql`
        subscription ErroredActionJobEventsSubscription(
          $jobId: ID!
          $eventTypes: [JobEventType]!
          $actionTypes: [ActionType]!
          $subscriptionId: ID!
          $eventsAfter: String
        ) {
          erroredActionJobEventsSubscription(
            jobId: $jobId
            eventTypes: $eventTypes
            actionTypes: $actionTypes
            subscriptionId: $subscriptionId
            eventsAfter: $eventsAfter
          ) {
            ...JobEventFields
          }
        }
        ${JOB_EVENT_FIELDS}
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.erroredActionJobEventsSubscription;
        if (response) {
          cb(mapFromBackend([response])[0]);
        }
      },
      error: console.error,
    });

  const heartbeatSubscription: HeartbeatSubscription = {
    subscriptionId,
    unsubscribeFn: () => subscription.unsubscribe(),
  };

  return heartbeatSubscription;
}

export async function pauseProgram(labId: string, programId = '0') {
  const result = await apollo.mutate<{ pauseProgram: string[] }>({
    variables: { labId, programId },
    mutation: gql`
      mutation PauseProgram($labId: ID!, $programId: ID!) {
        pauseProgram(labId: $labId, programId: $programId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.pauseProgram;
  }
}

export async function resumeProgram(labId: string, programId = '0') {
  const result = await apollo.mutate<{ resumeProgram: string[] }>({
    variables: { labId, programId },
    mutation: gql`
      mutation ResumeProgram($labId: ID!, $programId: ID!) {
        resumeProgram(labId: $labId, programId: $programId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.resumeProgram;
  }
}

export async function cancelProgram(labId: string, programId = '0') {
  const result = await apollo.mutate<{ cancelProgram: string[] }>({
    variables: { labId, programId },
    mutation: gql`
      mutation CancelProgram($labId: ID!, $programId: ID!) {
        cancelProgram(labId: $labId, programId: $programId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.cancelProgram;
  }
}

export async function pauseJob(jobId: string) {
  const result = await apollo.mutate<{ pauseJob: string }>({
    variables: { jobId },
    mutation: gql`
      mutation PauseJob($jobId: ID!) {
        pauseJob(jobId: $jobId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.pauseJob;
  }
}

export async function resumeJob(jobId: string) {
  const result = await apollo.mutate<{ resumeJob: string }>({
    variables: { jobId },
    mutation: gql`
      mutation ResumeJob($jobId: ID!) {
        resumeJob(jobId: $jobId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.resumeJob;
  }
}

export async function cancelJob(jobId: string) {
  const result = await apollo.mutate<{ cancelJob: string }>({
    variables: { jobId },
    mutation: gql`
      mutation CancelJob($jobId: ID!) {
        cancelJob(jobId: $jobId)
      }
    `,
  });

  if (result && result.data) {
    return result.data.cancelJob;
  }
}

export async function batchPauseJobs(jobIds: string[]) {
  const result = await apollo.mutate<{ pauseJobs: string }>({
    variables: { jobIds },
    mutation: gql`
      mutation PauseJobs($jobIds: [ID]!) {
        pauseJobs(jobIds: $jobIds)
      }
    `,
  });

  if (result && result.data) {
    return result.data.pauseJobs;
  }
}

export async function batchCancelJobs(jobIds: string[]) {
  const result = await apollo.mutate<{ cancelJobs: string }>({
    variables: { jobIds },
    mutation: gql`
      mutation CancelJobs($jobIds: [ID]!) {
        cancelJobs(jobIds: $jobIds)
      }
    `,
  });

  if (result && result.data) {
    return result.data.cancelJobs;
  }
}

export async function batchResumeJobs(jobIds: string[]) {
  const result = await apollo.mutate<{ resumeJobs: string }>({
    variables: { jobIds },
    mutation: gql`
      mutation ResumeJobs($jobIds: [ID]!) {
        resumeJobs(jobIds: $jobIds)
      }
    `,
  });

  if (result && result.data) {
    return result.data.resumeJobs;
  }
}

export async function finishAssistant(
  assistantId: string,
  userId: string,
  finishTime: string
): Promise<SimplifiedState> {
  const response = await apollo.mutate({
    variables: { assistantId, userId, finishTime },
    mutation: gql`
      mutation FinishAssistant(
        $assistantId: ID!
        $userId: ID!
        $finishTime: String!
      ) {
        finishAssistant(
          assistantId: $assistantId
          userId: $userId
          finishTime: $finishTime
          skipped: true
        ) {
          id
          state
        }
      }
    `,
  });
  if (response.data?.finishAssistant) {
    return response.data.finishAssistant.state;
  }
  return SimplifiedState.ERROR;
}

export async function finishDialogAction(
  actionId: string,
  userId: string,
  finishTime: string
): Promise<SimplifiedState> {
  const response = await apollo.mutate<{ finishDialogAction: Action }>({
    variables: { actionId, userId, finishTime },
    mutation: gql`
      mutation FinishDialogAction(
        $actionId: ID!
        $userId: ID!
        $finishTime: String!
      ) {
        finishDialogAction(
          actionId: $actionId
          userId: $userId
          finishTime: $finishTime
        ) {
          id
          state
        }
      }
    `,
  });
  if (response.data?.finishDialogAction) {
    return response.data.finishDialogAction.state;
  }
  return SimplifiedState.ERROR;
}

export async function getJobNotes(id: string): Promise<Job | null> {
  const response = await apollo.query<{ job: Job }>({
    variables: { id },
    query: gql`
      query GetJobNotes($id: ID!) {
        job(id: $id) {
          common {
            revision
          }
          notes {
            userId
            createdTimestamp
            id
            text
          }
        }
      }
    `,
  });

  if (response.data.job) {
    return response.data.job;
  }
  return null;
}

export async function addJobNote(
  jobId: string,
  revision: string,
  note: string
): Promise<Job> {
  const response = await apollo.mutate<{ addJobNote: Job }>({
    variables: {
      jobId,
      revision,
      note,
    },
    mutation: gql`
      mutation AddJobNote($jobId: ID!, $revision: String!, $note: String!) {
        addJobNote(jobId: $jobId, revision: $revision, note: $note) {
          id
          common {
            revision
          }
          notes {
            userId
            createdTimestamp
            id
            text
          }
        }
      }
    `,
  });

  return response.data?.addJobNote || nullJob();
}

export async function refetchActionSchema(actionId: string): Promise<Action> {
  const response = await apollo.query<{ action: Action }>({
    variables: {
      id: actionId,
    },
    query: gql`
      query Action($id: ID!) {
        action(id: $id) {
          id
          description
          schema
          parameterValues
        }
      }
    `,
  });
  if (response.data?.action) {
    return response.data.action;
  }
  return nullAction();
}

export function startSubscribeAssistantCommands(
  labId: string,
  actionId: string,
  cb: (full: boolean, commands: ActionCommandMessage[]) => void
) {
  interface SubscriptionMessage {
    full: boolean;
    actionCommands: ActionCommandMessage[];
  }
  const subscription = apollo
    .subscribe<{ actionCommands: SubscriptionMessage }>({
      fetchPolicy: 'no-cache',
      variables: {
        labId,
        actionId,
        clientId: ClientWriterId,
      },
      query: gql`
        subscription ActionCommands($labId: ID, $actionId: ID, $clientId: ID!) {
          actionCommands(
            labId: $labId
            actionId: $actionId
            clientId: $clientId
          ) {
            full
            actionCommands {
              actionId
              command
            }
          }
        }
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.actionCommands;
        if (response) {
          cb(response.full, response.actionCommands);
        }
      },
    });

  return () => subscription.unsubscribe();
}

export function startSubscribeLabState(
  labId: string,
  programId: string,
  cb: (full: boolean, labs: Lab[]) => void
) {
  interface SubscriptionMessage {
    full: boolean;
    labsState: Lab[];
  }

  const subscription = apollo
    .subscribe<{ labsState: SubscriptionMessage }>({
      fetchPolicy: 'no-cache',
      variables: {
        labId,
        programId,
        clientId: ClientWriterId,
      },
      query: gql`
        subscription LabState($labId: ID!, $programId: ID!, $clientId: ID!) {
          labsState(labId: $labId, programId: $programId, clientId: $clientId) {
            full
            labsState {
              id
              state
              numRunningJobs
              numRunningNeedAssistanceJobs
              numRunningWithAssistanceJobs
              numRunningWithErrorJobs
              numOnHoldJobs
              numInitializedJobs
              numPausedJobs
            }
          }
        }
      `,
    })
    .subscribe({
      next: (r) => {
        const response = r.data?.labsState;
        if (response) {
          cb(response.full, response.labsState);
        }
      },
    });

  return () => subscription.unsubscribe();
}

export async function validateJobs(
  jobIds: string[],
  validationStage: InteractivityHookStage
): Promise<JobValidationResponse> {
  const response = await apollo.query<{ validateJobs: JobValidationResponse }>({
    variables: {
      jobIds,
      validationStage,
    },
    query: gql`
      query ValidateJobs(
        $jobIds: [ID]!
        $validationStage: InteractivityHookStage!
      ) {
        validateJobs(jobIds: $jobIds, validationStage: $validationStage) {
          success
          errors
        }
      }
    `,
  });
  if (!response.errors) {
    return {
      success: response.data.validateJobs.success,
      errors: response.data.validateJobs.errors,
    };
  }
  return {
    success: false,
    errors: response.errors.map((e) => e.message),
  };
}

export async function scheduleJob(
  jobId: string,
  jobIds: string[],
  labId: string,
  constraint: string,
  time: string,
  previewScheduleId = '',
  scheduleType = SchedulingType.SCHEDULE_TYPE_SOLVE_GLOBAL
): Promise<ScheduleResponse> {
  const response = await apollo.mutate<{
    scheduleJob: ScheduleResponse;
  }>({
    variables: {
      jobId,
      jobIds,
      labId,
      constraint,
      time,
      previewScheduleId,
      scheduleType,
    },
    mutation: gql`
      mutation ScheduleJob(
        $jobId: ID!
        $jobIds: [String]!
        $labId: ID!
        $time: String
        $constraint: String!
        $previewScheduleId: String
        $scheduleType: SchedulingType
      ) {
        scheduleJob(
          jobId: $jobId
          jobIds: $jobIds
          labId: $labId
          time: $time
          constraint: $constraint
          previewScheduleId: $previewScheduleId
          scheduleType: $scheduleType
        ) {
          ...ScheduleResponseFields
        }
      }
      ${SCHEDULE_RESPONSE_FIELDS}
    `,
  });
  if (response.data?.scheduleJob) {
    return response.data.scheduleJob;
  }
  return {
    scheduleId: '',
    solutionInfo: {
      solutionFound: false,
    },
    errors: [],
  };
}

export async function unscheduleJobs(jobIds: string[], labId: string) {
  const response = await apollo.mutate<{
    unscheduleJobs: ScheduleResponse;
  }>({
    variables: {
      jobIds,
      labId,
    },
    mutation: gql`
      mutation UnscheduleJobs($jobIds: [String]!, $labId: String!) {
        unscheduleJobs(jobIds: $jobIds, labId: $labId) {
          ...ScheduleResponseFields
        }
      }
      ${SCHEDULE_RESPONSE_FIELDS}
    `,
  });
  if (response.data?.unscheduleJobs) {
    return response.data.unscheduleJobs;
  }
  return {
    scheduleId: '',
    solutionInfo: {
      solutionFound: false,
    },
    errors: [],
  };
}

export async function unscheduleAllJobs(labId: string) {
  const response = await apollo.mutate<{
    unscheduleAllJobs: ScheduleResponse;
  }>({
    variables: {
      labId,
    },
    mutation: gql`
      mutation UnscheduleAllJobs($labId: String!) {
        unscheduleAllJobs(labId: $labId) {
          ...ScheduleResponseFields
        }
      }
      ${SCHEDULE_RESPONSE_FIELDS}
    `,
  });
  if (response.data?.unscheduleAllJobs) {
    return response.data.unscheduleAllJobs;
  }
  return {
    scheduleId: '',
    solutionInfo: {
      solutionFound: false,
    },
    errors: [],
  };
}

export async function rescheduleAllJobs(labId: string) {
  const response = await apollo.mutate<{
    rescheduleAllJobs: ScheduleResponse;
  }>({
    variables: {
      labId,
    },
    mutation: gql`
      mutation RescheduleAllJobs($labId: String!) {
        rescheduleAllJobs(labId: $labId) {
          ...ScheduleResponseFields
        }
      }
      ${SCHEDULE_RESPONSE_FIELDS}
    `,
  });
  if (response.data?.rescheduleAllJobs) {
    return response.data.rescheduleAllJobs;
  }
  return {
    scheduleId: '',
    solutionInfo: {
      solutionFound: false,
    },
    errors: [],
  };
}

export async function previewJob(
  jobId: string,
  jobIds: string[],
  labId: string,
  constraint: string,
  time: string
) {
  const response = await apollo.mutate<{
    previewJob: PreviewJobResponse;
  }>({
    variables: {
      jobId,
      jobIds,
      labId,
      constraint,
      time,
    },
    mutation: gql`
      mutation PreviewJob(
        $jobId: ID!
        $jobIds: [String]!
        $labId: ID!
        $time: String
        $constraint: String!
      ) {
        previewJob(
          jobId: $jobId
          jobIds: $jobIds
          labId: $labId
          time: $time
          constraint: $constraint
        ) {
          generatedAPrioriScheduleIdOfBestSolution
          scheduleResponses {
            scheduleId
            solutionInfo {
              solutionFound
              solutionIsOptimal
            }
            errors {
              errorType {
                ... on ScheduleError_MissingActorAbility {
                  __typename
                  missingActorAbility {
                    actorId
                    abilityName
                  }
                }
              }
            }
            generatedAPrioriScheduleId
            actionSchedules {
              actionId
              scheduledActor {
                actorId
              }
              startTimestamp
              endTimestamp
              msDuration
            }
            jobSchedules {
              jobId
              scheduleId
              startTimestamp
              endTimestamp
              msDuration
            }
          }
        }
      }
    `,
  });

  if (response.data && response.data.previewJob) {
    return response.data.previewJob;
  }
  return null;
}

export async function getProgramGraph(
  createdBy: string,
  labId: string,
  jobId?: string
) {
  interface ProgramInfo {
    numEdges: number;
    numNodes: number;
    hasError: boolean;
    errorMessage: string;
    returnPlot: string;
  }
  const response = await apollo.query<{ programInfo: ProgramInfo }>({
    variables: {
      createdBy,
      labId,
      jobId,
    },
    query: gql`
      query ProgramInfo($createdBy: String!, $labId: String!, $jobId: String) {
        programInfo(createdBy: $createdBy, labId: $labId, jobId: $jobId) {
          numEdges
          numNodes
          hasError
          errorMessage
          returnPlot
          returnPlotInFormat
        }
      }
    `,
  });
  if (response.data && response.data.programInfo) {
    return response.data.programInfo;
  }
}
