import { reactive } from 'vue';
import {
  Adapter,
  AdapterHealth,
  AdapterHealthState,
  HealthState,
} from './model';
import ConfigController from '@/clients/config';
import OpsController from '@/clients/ops';
import * as Apollo from './service.apollo';
import { formatDateTime } from '@/pages/utils';
import { difference } from 'lodash';

interface StateInterface {
  adapters: Record<string, Adapter>;
  lastUpdated: string | null;
}

const ADAPTER_KILL_COOLDOWN = 10000;

export const getLastKilledLocalStorageKey = (adapter: Adapter) =>
  `lastKillTime-${adapter.id}`;

const getlastKilledTime = (adapter: Adapter): number => {
  const key = getLastKilledLocalStorageKey(adapter);
  const lastKillTime = localStorage.getItem(key);
  return lastKillTime ? parseInt(lastKillTime, 10) : 0;
};

const setLastKilledTime = (adapter: Adapter, time: number) => {
  const key = getLastKilledLocalStorageKey(adapter);
  localStorage.setItem(key, time.toString());
};

const updateKillEnabled = (adapter: Adapter) => {
  if (!adapter.killSupported) {
    adapter.killEnabled = false;
  }

  // then check for a user page refresh and make sure the button stays disabled across it
  adapter.killEnabled =
    Date.now() >= getlastKilledTime(adapter) + ADAPTER_KILL_COOLDOWN;
};

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private refreshTimeout: NodeJS.Timeout | null = null;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      adapters: {},
      lastUpdated: null,
    });

    setInterval(
      () => Object.values(this.state.adapters).forEach(updateKillEnabled),
      1000
    );
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  private storeAdapter(adapter: Adapter, labId: string) {
    adapter.health = getAdapterHealth(adapter);
    adapter.labId = labId;
    updateKillEnabled(adapter);
    this.state.adapters[adapter.id] = adapter;
    if (adapter.config) {
      adapter.config.labId = labId;
      ConfigController.Instance.setConfig(adapter.config);
    }
    adapter.state = {
      ...adapter.state,
      configured: adapter.config?.resolved || false,
      connected: adapter.activeSubscription || false,
      connecting: false,
      removing: false,
    };
  }

  private deleteAdapter(adapterId: string) {
    delete this.state.adapters[adapterId];
    // adapter manager will delete the config for us, but we need to let the config controller know
    ConfigController.Instance.removeConfig(adapterId);
  }

  public get lastUpdated(): string | null {
    return this.state.lastUpdated;
  }

  public refreshHealthStatus = async () => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(
      () => this.refreshHealthStatus(),
      10 * 1000
    );

    const labId = OpsController.Instance.selectedLabId;
    if (labId) {
      const adapterHealths = await Apollo.getAdapterHealth(labId);
      // we can transition an adapter that is "connecting" or "removing" to "connected" or "disconnected"
      // based on whether it shows up in the health status or not.
      const incomingAdapterIds = adapterHealths.map((health) => health.id);
      const existingAdapterIds = Object.entries(this.state.adapters)
        .filter((e) => !e[1].state.connecting)
        .map((e) => e[0]);
      const addedAdapterIds = difference(
        incomingAdapterIds,
        existingAdapterIds
      );
      const removedAdapterIds = difference(
        existingAdapterIds,
        incomingAdapterIds
      );
      for (const adapterId of addedAdapterIds) {
        // recently added adapter, fetch its details
        const storedAdapter = this.getAdapter(adapterId);
        if (!storedAdapter?.scope) {
          const adapter = await this.dispatchGetAdapter(labId, adapterId);
          this.storeAdapter(adapter, labId);
        }
      }
      for (const adapterId of removedAdapterIds) {
        // recently removed adapter, delete it
        this.deleteAdapter(adapterId);
      }
      adapterHealths.forEach((health) => {
        const adapter = this.getAdapter(health.id);
        if (adapter) {
          adapter.health = getAdapterHealth({
            ...adapter,
            healthMonitors: health.healthMonitors,
          });
          adapter.activeSubscription = health.activeSubscription;
          adapter.healthMonitors = health.healthMonitors;
        }
      });
    }

    this.state.lastUpdated = formatDateTime(new Date()).datetime;
  };

  public async dispatchGetAdapters(labId: string): Promise<Adapter[]> {
    const adapters = await Apollo.getAdapters(labId);
    adapters.forEach((adapter) => {
      this.storeAdapter(adapter, labId);
    });
    return Object.values(this.state.adapters);
  }

  public async dispatchGetAdapter(
    labId: string,
    adapterId: string
  ): Promise<Adapter> {
    const adapter = await Apollo.getAdapter(labId, adapterId);
    this.storeAdapter(adapter, labId);
    return adapter;
  }

  public async dispatchAddAdapter(
    labId: string,
    name: string,
    image: string
  ): Promise<Adapter> {
    const adapter = await Apollo.addAdapter(labId, name, image);
    adapter.state.connecting = true;
    this.state.adapters[adapter.id] = adapter;
    return adapter;
  }

  public async dispatchDeleteAdapter(id: string) {
    const adapter = this.getAdapter(id);
    if (adapter) {
      adapter.state.removing = true;
      await Apollo.removeAdapter(id, adapter.labId);
    }
    return false;
  }

  public async dispatchDisableAdapter(
    adapterId: string,
    labId: string,
    disable = true
  ) {
    const success = await Apollo.setBannedConnection(adapterId, labId, disable);
    const adapter = this.getAdapter(adapterId);
    if (success && adapter) {
      adapter.state.disabled = disable;
      adapter.banned = disable;
    } else if (success) {
      this.state.adapters[adapterId] = {
        ...this.state.adapters[adapterId],
        numInstances: 0,
        banned: disable,
        state: {
          connected: false,
          configured: false,
          disabled: disable,
        },
      };
    }
  }

  public dispatchKillAdapter(adapter: Adapter) {
    if (!adapter.scope || !adapter.killEnabled) {
      return;
    }

    setLastKilledTime(adapter, Date.now());
    return Apollo.killAdapter(adapter.scope);
  }

  public getAdapters(labId: string): Adapter[] {
    return Object.values(this.state.adapters).filter((a) => a.labId === labId);
  }

  public getAdapter(id: string): Adapter | undefined {
    return this.state.adapters[id];
  }
}

export function getConnectedStateString(adapter: Adapter): string {
  if (adapter.state.connecting) {
    return 'Deploying...';
  } else if (adapter.state.removing) {
    return 'Removing...';
  } else if (adapter.state.disabled) {
    return 'Disabled';
  } else if (adapter.state.connected && adapter.numInstances > 1) {
    return `${adapter.numInstances} instances connected`;
  } else if (adapter.state.connected) {
    return 'Connected';
  }
  return 'Disconnected';
}

export function getConfiguredStateString(adapter: Adapter): string {
  if (adapter.state.configured && adapter.config?.resolved) {
    return 'Configured';
  } else if (adapter.state.configured && !adapter.config?.resolved) {
    return 'Not configured';
  }
  return 'No configuration';
}

const getAdapterHealth = (adapter: Adapter): AdapterHealth => {
  const artificialConnection = (): HealthState => {
    if (
      (adapter.banned && !adapter.state.configured) ||
      !adapter.activeSubscription
    ) {
      return HealthState.ERROR;
    } else if (
      !adapter.banned &&
      adapter.state.configured &&
      adapter.activeSubscription
    ) {
      return HealthState.OK;
    } else {
      return HealthState.WARNING;
    }
  };
  const monitors = Object.entries(adapter.healthMonitors);
  const getAggregateHealthStatus = (
    connectionHealth: boolean,
    healthInfoHealth: boolean
  ): AdapterHealthState => {
    if (connectionHealth && healthInfoHealth) {
      return AdapterHealthState.ALL_CONNECTIONS_HEALTHY;
    } else if (connectionHealth || healthInfoHealth) {
      return AdapterHealthState.SOME_CONNECTIONS_HEALTHY;
    } else if (!connectionHealth && !healthInfoHealth) {
      return AdapterHealthState.NO_CONNECTIONS_HEALTHY;
    }
    return AdapterHealthState.UNKNOWN;
  };

  const connectionHealth = !adapter.banned && !!adapter.activeSubscription;
  const clientHealth =
    monitors.length === 0
      ? !!adapter.activeSubscription
      : monitors.every((hi) => hi[1].state === HealthState.OK);
  const clientHealthStatus = (): AdapterHealthState => {
    if (monitors.length === 0) {
      return AdapterHealthState.UNKNOWN;
    }
    if (monitors.every((c) => c[1].state === HealthState.OK)) {
      return AdapterHealthState.ALL_CONNECTIONS_HEALTHY;
    } else if (monitors.some((hi) => hi[1].state === HealthState.OK)) {
      return AdapterHealthState.SOME_CONNECTIONS_HEALTHY;
    }
    return AdapterHealthState.NO_CONNECTIONS_HEALTHY;
  };

  return {
    artificialConnectionHealth: artificialConnection(),
    clientHealthStatus: clientHealthStatus(),
    aggregateHealth: getAggregateHealthStatus(connectionHealth, clientHealth),
  };
};
