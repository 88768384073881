
import { defineComponent, computed, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MessageController from '@/clients/messenger';
import UIController from '@/clients/ui';
import UserController from '@/clients/users';
import Navbar from './navbar/index.vue';
import Leftbar from './navbar/Leftbar.vue';
import LabCentricLeftNav from './navbar/LabCentricLeftNav.vue';
import DrawerLeft from '@/components/Drawers/DrawerLeft.vue';
import DrawerRight from '@/components/Drawers/DrawerRight.vue';
import BottomDrawer from '@/components/Drawers/BottomDrawer.vue';
import { LOGOUT_REDIRECT_URI } from '@/clients/utils';
import CookieBanner from './components/CookieBanner.vue';
import { NAMES } from './router';

export default defineComponent({
  components: {
    Navbar,
    Leftbar,
    LabCentricLeftNav,
    DrawerLeft,
    DrawerRight,
    BottomDrawer,
    CookieBanner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const appBody: Ref<HTMLElement | null> = ref(null);
    const centerPortal: Ref<HTMLElement | null> = ref(null);
    const leftDrawer: Ref<HTMLElement | null> = ref(null);
    const rightDrawer: Ref<HTMLElement | null> = ref(null);
    const DEFAULT_LEFT_DRAWER_WIDTH = 450;
    const DEFAULT_RIGHT_DRAWER_WIDTH = 530;
    const DEFAULT_BOTTOM_DRAWER_HEIGHT = 360;
    const leftWidth = ref(0);
    const rightWidth = ref(0);
    const bottomHeight = ref(0);
    const bottomTop = ref(0);
    const showCookieBanner = computed(
      () =>
        !UserController.Instance.currentUser.complianceData.cookiesAccepted &&
        route.name !== NAMES.PRIVACY
    );
    const centerDrawerVisible = computed(
      () => UIController.Instance.displayCenterDrawer
    );
    const rightDrawerVisible = computed(
      () =>
        UIController.Instance.displayRightDrawer && !centerDrawerVisible.value
    );
    const leftDrawerVisible = computed(
      () => UIController.Instance.displayLeftDrawer
    );
    const bottomDrawerVisible = computed(
      () => UIController.Instance.displayBottomDrawer
    );
    const showRedLine = computed(() =>
      UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING')
    );

    const isLeftbarVisible = computed(() => {
      const visibleRoutes = [
        'home',
        'files',
        'results',
        'tokens',
        'labs',
        'assistants',
        'settings',
        'workflows',
      ];
      const path = route.path;

      if (path === '/') {
        return true;
      }

      const result =
        visibleRoutes.filter((route) => path.match(route)).length > 0;
      return result;
    });

    const isNewLeftNavVisible = computed(() => {
      const path = route.path;
      return !!path.match('ops');
    });

    const isNavbarHidden = computed(() => {
      const hiddenRoutes = ['/lab'];
      const path = route.path;

      return hiddenRoutes.includes(path);
    });

    const handleLeftDrawerResize = (pos: number) => {
      leftWidth.value = pos - (leftDrawer.value?.offsetLeft || 0);
    };

    const handleRightDrawerResize = (pos: number) => {
      rightWidth.value = window.innerWidth - pos;
    };

    const handleBottomDrawerResize = (pos: number) => {
      console.log(`resize bottom`, pos);
      bottomHeight.value = window.innerHeight - pos;
      bottomTop.value = pos;
    };

    const rejectCookies = () => {
      // we can't close the window, so we redirect to the home page clearing the history
      window.location.href = LOGOUT_REDIRECT_URI || 'https://artificial.com';
    };

    const acceptCookies = () => {
      UserController.Instance.dispatchAcceptCookies();
    };

    watch(
      () => MessageController.Instance.message,
      (message) => {
        if (
          message?.to === 'dashboard' &&
          message.context === 'assistant' &&
          message.data?.op === 'goHome'
        ) {
          router.push('/');
        }
      },
      { deep: true }
    );

    watch(leftDrawerVisible, (visible) => {
      if (visible) {
        leftWidth.value = DEFAULT_LEFT_DRAWER_WIDTH;
      } else {
        leftWidth.value = 0;
      }
    });

    watch(rightDrawerVisible, (visible) => {
      if (visible) {
        rightWidth.value = DEFAULT_RIGHT_DRAWER_WIDTH;
      } else {
        rightWidth.value = 0;
      }
    });

    watch(bottomDrawerVisible, (visible) => {
      if (visible) {
        bottomHeight.value = DEFAULT_BOTTOM_DRAWER_HEIGHT;
        bottomTop.value = window.innerHeight - bottomHeight.value;
      } else {
        bottomHeight.value = 1;
        bottomTop.value = window.innerHeight;
      }
    });

    return {
      appBody,
      centerPortal,
      leftDrawer,
      rightDrawer,
      rightDrawerVisible,
      leftDrawerVisible,
      bottomDrawerVisible,
      showCookieBanner,
      leftWidth,
      rightWidth,
      bottomHeight,
      bottomTop,
      handleLeftDrawerResize,
      handleRightDrawerResize,
      handleBottomDrawerResize,
      showRedLine,
      isLeftbarVisible,
      isNewLeftNavVisible,
      isNavbarHidden,
      isLoading: computed(
        () => UIController.Instance.longNetworkCallInProgress
      ),
      version: `${process.env.VUE_APP_VERSION}_${GITCOMMITHASH}`,
      expertMode: computed(() => UIController.Instance.expert),
      rejectCookies,
      acceptCookies,
    };
  },
});
