import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/workflows-white.svg'
import _imports_1 from '@/assets/icons/assistant-white.svg'


const _withScopeId = n => (_pushScopeId("data-v-155ffc73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-info" }
const _hoisted_2 = { class: "top-row" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "job-name__text" }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "workflow"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "assistant"
}
const _hoisted_8 = { class: "dates" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "details-filter" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_action_banner = _resolveComponent("action-banner")!
  const _component_job_activity_timeline = _resolveComponent("job-activity-timeline")!
  const _component_job_activity_legacy = _resolveComponent("job-activity-legacy")!
  const _component_details_summary = _resolveComponent("details-summary")!
  const _component_job_resources = _resolveComponent("job-resources")!
  const _component_notes = _resolveComponent("notes")!
  const _component_retry_action_dialog = _resolveComponent("retry-action-dialog")!
  const _component_skip_action_dialog = _resolveComponent("skip-action-dialog")!
  const _component_action_data_entry_dialog = _resolveComponent("action-data-entry-dialog")!
  const _component_confirm_cancel_dialog = _resolveComponent("confirm-cancel-dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ops-job-details",
    onMouseenter: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isHovered = true)),
    onMouseleave: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isHovered = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        class: "close",
        onClick: _ctx.close,
        icon: "close"
      }, null, 8, ["onClick"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "job-name",
          ref: "jobName",
          title: _ctx.job.name
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.job.name), 1),
          (_ctx.expertMode)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                circle: "",
                text: "",
                dark: "",
                size: "small",
                icon: "CopyDocument",
                onClick: _ctx.copyJobId,
                title: "Copy Job ID"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3),
        (_ctx.job.action)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
            'defined-from',
            _ctx.job.action.actionType,
            _ctx.isDevMode ? 'dev-mode' : '',
          ]),
              title: _ctx.job.action.name ?? 'Unknown',
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToWorkflow && _ctx.goToWorkflow(...args)))
            }, [
              (_ctx.isWorkflow)
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7)),
              _createElementVNode("span", null, _toDisplayString(_ctx.job.action.name ?? 'Unknown'), 1)
            ], 10, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.createdTime)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "created",
              title: _ctx.createdTime.relative ?? ''
            }, " Created " + _toDisplayString(_ctx.createdTime.datetime ?? '--'), 9, _hoisted_9))
          : _createCommentVNode("", true),
        (_ctx.scheduledTime && _ctx.jobState.state === _ctx.SimplifiedState.INITIALIZED)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "scheduled",
              title: _ctx.scheduledTime.relative ?? ''
            }, " Scheduled to run " + _toDisplayString(_ctx.scheduledTime.datetime ?? '--'), 9, _hoisted_10))
          : _createCommentVNode("", true),
        (_ctx.endTime && _ctx.job.state === _ctx.SimplifiedState.FINISHED)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "finished",
              title: _ctx.endTime.relative ?? ''
            }, " Completed " + _toDisplayString(_ctx.endTime.datetime ?? '--'), 9, _hoisted_11))
          : _createCommentVNode("", true),
        (_ctx.endTime && _ctx.job.state === _ctx.SimplifiedState.CANCELLED)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "finished",
              title: _ctx.endTime.relative ?? ''
            }, " Canceled " + _toDisplayString(_ctx.endTime.datetime ?? '--'), 9, _hoisted_12))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_action_banner, {
          job: _ctx.job,
          action: _ctx.focusedAction,
          "lab-id": _ctx.labId,
          onSkipAssistant: _ctx.skipAssistant,
          onRunAssistant: _ctx.goToAssistant,
          onRetryAction: _ctx.handleRetryAction,
          onSkipAction: _ctx.handleSkipAction,
          onUserEntryPrompt: _ctx.openUserPromptDialog,
          onSkipUserEntryPrompt: _ctx.skipUserPrompt,
          onCancelJob: _cache[1] || (_cache[1] = ($event: any) => (_ctx.executeJobCommand(_ctx.CommandState.CANCEL))),
          onPauseJob: _cache[2] || (_cache[2] = ($event: any) => (_ctx.executeJobCommand(_ctx.CommandState.PAUSE))),
          onResumeJob: _cache[3] || (_cache[3] = ($event: any) => (_ctx.executeJobCommand(_ctx.CommandState.RESUME))),
          onUnscheduleJob: _cache[4] || (_cache[4] = ($event: any) => (_ctx.executeJobCommand(_ctx.CommandState.UNSCHEDULE)))
        }, null, 8, ["job", "action", "lab-id", "onSkipAssistant", "onRunAssistant", "onRetryAction", "onSkipAction", "onUserEntryPrompt", "onSkipUserEntryPrompt"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['filter', { active: _ctx.selectedFilter === filter }]),
            key: filter,
            onClick: ($event: any) => (_ctx.setSelectedFilter(filter))
          }, _toDisplayString(filter), 11, _hoisted_14))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      (
          _ctx.selectedFilter === 'ACTIVITY' &&
          _ctx.legacyOrJobEventsFinalized &&
          !_ctx.showLegacy
        )
        ? (_openBlock(), _createBlock(_component_job_activity_timeline, {
            key: 0,
            "job-id": _ctx.job.id,
            "lab-id": _ctx.job.labId,
            "job-name": _ctx.job.name,
            "gantt-view-controller": _ctx.ganttViewController,
            onUpdateSelectedJob: _ctx.updateSelectedJob
          }, null, 8, ["job-id", "lab-id", "job-name", "gantt-view-controller", "onUpdateSelectedJob"]))
        : (
          _ctx.selectedFilter === 'ACTIVITY' &&
          _ctx.legacyOrJobEventsFinalized &&
          _ctx.showLegacy
        )
          ? (_openBlock(), _createBlock(_component_job_activity_legacy, {
              key: 1,
              "job-id": _ctx.jobId,
              "is-workflow": _ctx.isWorkflow
            }, null, 8, ["job-id", "is-workflow"]))
          : _createCommentVNode("", true),
      (_ctx.selectedFilter === 'DETAILS')
        ? (_openBlock(), _createBlock(_component_details_summary, {
            key: 2,
            job: _ctx.job,
            actions: _ctx.actions,
            jobState: _ctx.jobState.state,
            onExecuteCommand: _ctx.executeJobCommand,
            onUpdateSelectedJob: _ctx.updateSelectedJob
          }, null, 8, ["job", "actions", "jobState", "onExecuteCommand", "onUpdateSelectedJob"]))
        : (_ctx.selectedFilter === 'RESULTS')
          ? (_openBlock(), _createBlock(_component_job_resources, {
              key: 3,
              job: _ctx.job,
              onUpdateSelectedJob: _ctx.updateSelectedJob
            }, null, 8, ["job", "onUpdateSelectedJob"]))
          : _createCommentVNode("", true),
      (_ctx.selectedFilter === 'NOTES')
        ? (_openBlock(), _createBlock(_component_notes, {
            key: 4,
            job: _ctx.job
          }, null, 8, ["job"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_retry_action_dialog, {
      modelValue: _ctx.retryActionDialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.retryActionDialog) = $event)),
      data: _ctx.retryActionData,
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.retryActionDialog = false))
    }, null, 8, ["modelValue", "data"]),
    _createVNode(_component_skip_action_dialog, {
      modelValue: _ctx.skipActionDialog,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.skipActionDialog) = $event)),
      labId: _ctx.labId,
      action: _ctx.actionToSkip,
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.skipActionDialog = false))
    }, null, 8, ["modelValue", "labId", "action"]),
    _createVNode(_component_action_data_entry_dialog, {
      modelValue: _ctx.dataEntryDialog,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataEntryDialog) = $event)),
      action: _ctx.promptAction,
      onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.dataEntryDialog = false))
    }, null, 8, ["modelValue", "action"]),
    _createVNode(_component_confirm_cancel_dialog, {
      visible: _ctx.showCancelConfirmDialog,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showCancelConfirmDialog) = $event)),
      "job-id": _ctx.job.id
    }, null, 8, ["visible", "job-id"])
  ], 32))
}