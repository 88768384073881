import { DateTime } from 'luxon';
import AssistantIcon from '@/assets/icons/assistant.svg';
import WorkflowIcon from '@/assets/icons/workflows.svg';
import { JobEventType } from '@/clients/ops';

export interface GanttDataFilters {
  completedAfter: DateTime;
  startedBefore: DateTime;
  limit: number;
  offset: number;
}

export interface GanttBlockEvent {
  id: string;
  name: string;
  start: DateTime;
  end: DateTime;
  relativeOffset: number;
  assignedResource: string;
  color: { dark: string; light: string };
  locked: boolean;
  noCheckmark: boolean; // is this a dup of locked?
  popupDetails: EventDetailsD3Type;
  resourceId?: string; // this should probably move into some kind of callback/code injection from higher up
  compatibleResourceIds?: string[];
  actorRequestIndex: number | null;
  whichRowIsThisOn?: number; // hacky first pass
  boxColor?: { dark: string; light: string }; // internally used as the actual color we will use
  drawProjected: boolean;
  laneId?: string; // a way to know which lane we were from
  // oh the hacks...
  nextBlock?: GanttBlockEvent; // hacky field for now to let us slide all the boxes after a box
  prevBlock?: GanttBlockEvent; // hacky field for now to let us slide all the boxes after a box
  // probably make a new internal type that adds fields (not optionally)
}

export interface GanttPointEvent {
  id: string;
  type: JobEventType;
  time: DateTime;
  whichRowIsThisOn?: number; // hacky first pass
  laneId?: string; // a way to know which lane we were from
}
type GanttBlockFieldRef<T> = () => T;
export interface LineConnectorType {
  id: string; // boxId they associate with
  laneId: string;
  color: string;
  startTime: GanttBlockFieldRef<DateTime>;
  startRow: GanttBlockFieldRef<number>;
  endTime: GanttBlockFieldRef<DateTime>;
  endRow: GanttBlockFieldRef<number>;
  drawStartCircle?: boolean;
  drawEndCircle?: boolean;
}

export interface GanttResource {
  actorId: string;
  offline: boolean;
  serviceId: string | undefined;
}

export interface GanttLane {
  id: string;
  name: string;
  blockEvents: GanttBlockEvent[];
  pointEvents: GanttPointEvent[];
  start: DateTime;
  end: DateTime;
  drawEndCircle: boolean;

  // more hacky stuff that needs to live somewhere else
  lineConnectors?: LineConnectorType[];
}
// all of the input data required to render a gantt chart
export interface GanttData {
  snapshotTime: DateTime;
  lanes: GanttLane[];
  labId: string; // this should not live here!!!!
  resoures: GanttResource[];
}

const contentIcons = {
  NoIcon: '',
  AssistantIcon,
  WorkflowIcon,
};
export interface EventDetailsD3Type {
  start: DateTime;
  end: DateTime;
  titleString: string;
  contentString1: string;
  contentIcon1: keyof typeof contentIcons;
  contentString2: string;
  contentIcon2: keyof typeof contentIcons;
}

/**
 * Common helper functions
 */

/**
 * Attempt multiple ways to interpret a timestamp string into a DateTime
 * @param timestamp should eventually unify to just one way, but for now, we are receiving multiple formats from the backend
 * @returns a DateTime from the timestamp
 * @throws error if the timestamp can't be interpretted
 */
export function decodeTimestamp(timestamp: string): DateTime {
  if (timestamp !== '') {
    {
      const dt = DateTime.fromISO(timestamp);
      if (dt.isValid) return dt;
    }
    {
      const dt = DateTime.fromSQL(timestamp);
      if (dt.isValid) return dt;
    }
  }
  throw new Error(`Invalid timestamp:  ${timestamp}`);
}

/**
 * Attempt to encode a DateTime into a string representation for the back end
 * @param dateTime the DateTime to encode as a timestamp string
 * @returns a string representation for this DateTime that the back end will love
 * @error returns an empty string
 */
export function encodeTimestamp(dateTime: DateTime): string {
  const timestamp = dateTime.toUTC().toISO();
  if (timestamp === null) {
    console.error('Failed to convert DateTime to timestamp.');
    return '';
  }
  return timestamp;
}
