import * as d3 from 'd3';

export function customWheelPan(
  selection: d3.Selection<SVGSVGElement, number, SVGGElement, number>,
  zoom: d3.ZoomBehavior<SVGSVGElement, number>,
  event: WheelEvent
) {
  const node = selection.node();
  if (node) {
    const zoomScale = d3.zoomTransform(node).k;
    const xScroll = -event.deltaX / zoomScale;
    const yScroll = -event.deltaY / zoomScale;
    // these seem to do the same thing... but the docs suggest you should use the .call()?
    // zoom.translateBy(selection, xScroll, yScroll);
    selection.call(zoom.translateBy, xScroll, yScroll);
    // prevent default or chrome might try to do more stuff
    event.preventDefault();
  }
}

/**
 * Takes any flavor of brush selection and returns a valid 1d selection or null.
 * @param sel
 * @returns
 */
export function process1DBrushSelection(
  selection: d3.BrushSelection | null
): [number, number] | null {
  if (selection === null) {
    // console.log('empty brush selection');
    return null;
  }
  const brushStart = selection[0];
  if (Array.isArray(brushStart)) {
    console.log('got an unexpected array in brush selection');
    return null;
  }
  const brushEnd = selection[1];
  if (Array.isArray(brushEnd)) {
    console.log('got an unexpected array in brush selection');
    return null;
  }
  return [brushStart, brushEnd];
}

// fancy function to print info about all the elements that entered and exited a selection
// I could try adding this to the prototype for d3.Selection?
// how to use:
// .call(GanttHelpers.debugPrintDataJoin, (val) => console.log(val))
export function debugPrintDataJoin<
  GElement extends d3.BaseType,
  Datum,
  PElement extends d3.BaseType,
  PDatum
>(
  selection: d3.Selection<GElement, Datum, PElement, PDatum>,
  printCb: (val: Datum) => void
) {
  if (selection.enter().size() > 0) {
    console.groupCollapsed(`Entered elements (${selection.enter().size()}):`);
    selection.call((thing) => {
      thing.enter().each(function (p) {
        printCb(p);
      });
    });
    console.groupEnd();
  }
  if (selection.exit().size() > 0) {
    console.groupCollapsed(`Exited elements (${selection.exit().size()}):`);
    selection.call((thing) => {
      thing.exit<Datum>().each(function (p) {
        printCb(p);
      });
    });
    console.groupEnd();
  }
  return selection;
}

// a fancy little wrapper to allow optional transitions if duration > 0
export function animationWrapper<
  GElement extends d3.BaseType,
  Datum,
  PElement extends d3.BaseType,
  PDatum
>(
  selection: d3.Selection<GElement, Datum, PElement, PDatum>,
  duration: number,
  cb: (
    animatedSelection:
      | d3.Selection<GElement, Datum, PElement, PDatum>
      | d3.Transition<GElement, Datum, PElement, PDatum>
  ) => void
) {
  const animatedSelection =
    duration > 0 ? selection.transition().duration(duration) : selection;
  cb(animatedSelection);
  return animatedSelection;
}

// make an animated exit function?
