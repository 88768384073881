import { GanttRequest } from '@/clients/ops/model';
import { DateTime } from 'luxon';
import { decodeTimestamp } from './ganttModel';

export default function ganttDataSanity(originalData: GanttRequest): void {
  // Make a copy so we are sure nothing is changed by the time it hits the logs
  const data = structuredClone(originalData.jobs);
  const nowTime = decodeTimestamp(originalData.projectionsComputedAt);
  const diffInMillis = Math.abs(
    nowTime.diff(DateTime.now()).as('milliseconds')
  );
  if (diffInMillis > 60000) {
    console.log(
      'Projected data is more than 1 minute out of sync with local time.'
    );
  }
  // First, just log all the data I got
  let index = 0;
  console.groupCollapsed(`Data received from backend at ${nowTime}:`);
  for (const job of data) {
    console.log(`Row ${index++}: `, job);
  }
  console.groupEnd();

  console.groupCollapsed('Problems found in data from backend.');
  const jobIdSet = new Set<string>();
  for (const job of data) {
    let inTheFuture = false;

    // verify that all job IDs are unique
    if (jobIdSet.has(job.id)) {
      console.log(`${job.id} is a non-unique ID.  `, job);
    }
    jobIdSet.add(job.id);

    const activities = job.activities;
    // right now, we require all jobs to have at least one activity
    if (activities.length === 0) {
      console.log(`${job.id} has no activities.  `, job);
      continue;
    }

    // used to ensure all activities are in order
    let testTime = decodeTimestamp(activities[0].start);

    // add a character on the front - hack
    if (!isValidSelector('a' + job.id)) {
      console.log(`${job.id} is not a valid selector.  `, job);
    }

    // check for empty start times
    if (job.start === '') {
      console.log(`${job.name} had an empty start time.  `, job);
    } else if (decodeTimestamp(job.start) > testTime) {
      console.log(`${job.name} had an activity before it's start time.  `, job);
    }

    // do I need to do any test for job end time?
    if (job.end === '') {
      const finalActivity = activities[activities.length - 1];
      // cases where job end can be empty
      // if your final activity is projected
      // if your final activity is not projected, but it's end time is "now"
      if (finalActivity.projected) {
        // we good
      } else if (decodeTimestamp(finalActivity.end).equals(nowTime)) {
        // we good
      } else {
        // you can only have an empty end state if you are not finished running yet
        console.log(`${job.name} historical job had an empty end time.  `, job);
      }
    } else if (
      decodeTimestamp(job.end) <
      decodeTimestamp(activities[activities.length - 1].end)
    ) {
      console.log(`${job.name} had an activity after it's end time.  `, job);
    }

    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];

      // add a character on the front - hack
      if (!isValidSelector('a' + activity.id)) {
        console.log(`${activity.id} is not a valid selector.  `, job);
      }

      // it is no longer allowed to have a null state
      if (activity.state === null) {
        console.log(`${job.name}::${activity.name} had a null state.  `, job);
        // uncomment this after michael's update to add state to future activities
        // continue;
      }

      // once we have encountered projected
      if (inTheFuture && !activity.projected) {
        console.log(
          `${job.name}::${activity.name} had non-projected data after projected data.  `,
          job
        );
      }
      inTheFuture = inTheFuture || activity.projected;

      // all activities MUST have a start time
      if (activity.start === '') {
        console.log(
          `${job.name}::${activity.name} has an empty start time.  `,
          job
        );
        continue;
      }
      const activityStart = decodeTimestamp(activity.start);
      if (activityStart < testTime) {
        console.log(
          `${job.name}::${activity.name} started before the previous activity ended.  `,
          job
        );
        continue;
      }
      testTime = activityStart;

      // all activities MUST have an end time
      if (activity.end === '') {
        console.log(
          `${job.name}::${activity.name} has an empty end time.  `,
          job
        );
        continue;
      }
      const activityEnd = decodeTimestamp(activity.end);
      if (activityEnd <= testTime) {
        console.log(
          `${job.name}::${activity.name} ends before it starts.  `,
          job
        );
        continue;
      }
      testTime = activityEnd;

      if (activity.projected) {
        // check that this activity is completely in the future
        if (activityStart < nowTime) {
          console.log(
            `${job.name}::${activity.name} is predicted in the past.  `,
            job
          );
        }
      } else {
        // check that this activity is completely in the past
        if (activityEnd > nowTime) {
          console.log(
            `${job.name}::${activity.name} is historical in the future.  `,
            job
          );
        }
      }
    }
  }
  console.groupEnd();
  return;
}

// use to validate that an ID can be used as a CSS selector
function isValidSelector(selector: string) {
  try {
    document.querySelector(selector);
    return true;
  } catch (e) {
    return false;
  }
}
