import { EventDispatcher } from 'three';
import { cloneDeep } from 'lodash';
import OpsController from '@/clients/ops/controller';
import {
  GanttDataFilters,
  GanttBlockEvent,
  GanttData,
  GanttPointEvent,
  EventDetailsD3Type,
  GanttLane,
  encodeTimestamp,
  decodeTimestamp,
  GanttResource,
} from './ganttModel';
import {
  GanttRequest,
  GanttSetActivityOffsetType,
  GanttSetActivityDurationType,
  GanttRescheduleActorAssignmentType,
  GanttUpdateActorOfflineStateType,
} from '@/clients/ops/model';
import ganttDataSanity from './ganttDataSanity';
import { JobEventType } from '@/clients/ops';
import ActionController from '@/clients/action';
import getFakeGanttData from '@/pages/Ops/components/JobCreation/fakeGanttDataGen';
import { testData } from './ganttTestData';

const useTestData = false;

/**
 * GOALS/TODO
 * Could/should make the data readonly (at least most fields) so users don't accidentally change it
 * Find a better place for the data conversion - where to draw the line for data conversion provided here?
 * Need to re-add mechanism to get more data when you scroll around?  it still has issues
 * Should the visible view area of the gantt live here?  in another controller?  it is/could be related to the data window but I'm looking for ways to not put everything in this file
 * xstate? currently have a mock up for the data update state control - use more?
 *
 *  */

interface GanttDataEventMap {
  UPDATE: { newValue: GanttData; oldValue: GanttData | undefined };
  DISPOSE: object;
}

/**
 * Centralized store for the gantt data in a gantt chart
 */
export default class GanttController extends EventDispatcher<GanttDataEventMap> {
  private _dataFilters: GanttDataFilters;
  private _ganttData: GanttData | undefined = undefined;
  private _flatBoxList: GanttBlockEvent[] = [];
  /** a promise that resolves once the controller is ready to use */
  private _initializationComplete: Promise<boolean>;
  /** a simple way to toggle data updates on and off when desired */
  private _suspendDataUpdates = false;

  /**
   * Create a new controller. You must create a new controller to go along with every gantt chart
   * @param labId ID of the lab we are viewing gantt data for
   * @param includeProjections should this include schedule projection data too
   * @param dataFilters initial view filters for the data
   */
  constructor(
    private readonly _labId: string,
    private readonly _includeProjections: boolean,
    dataFilters: GanttDataFilters,
    private readonly _fakeDataCount: number
  ) {
    super();

    // setup initial data for gantt controller
    this._dataFilters = cloneDeep(dataFilters);
    this._initializationComplete = this.initGanttController();

    console.log(
      `GanttController started for lab: ${this.labId} with includeProjections: ${this.includeProjections}.  `,
      this._dataFilters
    );
  }

  dispose() {
    console.log('disposing ganttController');
    // how should this work?  notifications to things that are asking for timed updates?
    this.dispatchEvent({ type: 'DISPOSE' });
  }

  private async initGanttController() {
    await this.updateGanttData(this.dataFilters);
    return true;
  }

  /**
   *
   * @param dataFilters add comment here
   * @returns promise that resolves to true if the update happened successfully
   */
  async updateGanttData(dataFilters?: GanttDataFilters): Promise<boolean> {
    // crude way to disable data updates
    if (this.suspendDataUpdates) {
      return false;
    }

    // optionally update the filter
    if (dataFilters) {
      this._dataFilters = cloneDeep(dataFilters);
    }

    // request the data
    const ganttRequest = useTestData
      ? testData
      : this._fakeDataCount > 0
      ? await getFakeGanttData(this._fakeDataCount)
      : await OpsController.Instance.dispatchGetGanttJobsWithProjections(
          this.labId,
          encodeTimestamp(this.dataFilters.completedAfter),
          encodeTimestamp(this.dataFilters.startedBefore),
          this.includeProjections,
          this.dataFilters.limit,
          this.dataFilters.offset
        );

    // do sanity checking
    ganttDataSanity(ganttRequest);

    // convert the data
    try {
      const oldValue = this._ganttData;
      const newValue = OldGanttConversions.initGanttData(
        ganttRequest,
        this.labId
      );

      // fire update event here
      this._ganttData = newValue;
      this.dispatchEvent({ type: 'UPDATE', newValue, oldValue });
    } catch (error) {
      console.error('Exception when processing gantt chart data.');
      return false;
    }
    return true;
  }

  /**
   * Sends an activity start offset to the back end
   * @param jobId
   * @param activityId
   * @param relativeOffset
   * @returns true if successful
   */
  async sendActivityOffset(
    jobId: string,
    activityId: string,
    relativeOffset: number
  ): Promise<boolean> {
    // could only send if they've changed
    const rescheduleData: GanttSetActivityOffsetType = {
      jobId,
      activityId,
      relativeOffset,
    };
    // console.log(rescheduleData);
    return OpsController.Instance.dispatchGanttSetActivityOffset(
      rescheduleData
    );
  }

  /**
   * Sends an activity duration hint to the back end
   * @param jobId
   * @param activityId
   * @param duration
   * @returns true if successful
   */
  async sendActivityDuration(
    jobId: string,
    activityId: string,
    duration: number
  ): Promise<boolean> {
    // could only send if they've changed
    const rescheduleData: GanttSetActivityDurationType = {
      jobId,
      activityId,
      duration,
    };
    // console.log(rescheduleData);
    return OpsController.Instance.dispatchGanttSetActivityDuration(
      rescheduleData
    );
  }

  /**
   * Sends an activity reschedule to the back end
   * @param jobId
   * @returns true if successful
   */
  async sendActivityType(
    jobId: string,
    actorId: string,
    actorRequestIndex: number
  ): Promise<boolean> {
    const rescheduleData: GanttRescheduleActorAssignmentType = {
      labId: this.labId,
      jobId,
      actorId,
      actorRequestIndex,
    };
    // console.log('resched attempt:  ', rescheduleData);
    // console.log(rescheduleData);
    return OpsController.Instance.dispatchGanttRescheduleActorAssignment(
      rescheduleData
    );
  }

  /**
   * Sends an activity reschedule to the back end
   * @param jobId
   * @returns true if successful
   */
  async sendActorOfflineStatus(
    actorId: string,
    offline: boolean,
    serviceId: string
  ): Promise<boolean> {
    const offlineData: GanttUpdateActorOfflineStateType = {
      labId: this.labId,
      actorId,
      offline,
      serviceId,
    };
    console.log('actor offline attempt:  ', offlineData);
    // console.log(rescheduleData);
    return OpsController.Instance.dispatchGanttUpdateActorOfflineState(
      offlineData
    );
  }

  getLaneById(laneId: string | undefined): GanttLane | undefined {
    if (!laneId) {
      return undefined;
    }
    const foundIdx = this.ganttData.lanes.findIndex(
      (item) => item.id === laneId
    );
    if (foundIdx >= 0) {
      return this.ganttData.lanes[foundIdx];
    }
    return undefined;
  }

  getResourceStatus(actorId: string) {
    return this.ganttData.resoures.find((val) => val.actorId === actorId);
  }

  /**
   * ACCESSORS
   */

  public get isInitComplete(): Promise<boolean> {
    return this._initializationComplete;
  }

  public get labId(): string {
    return this._labId;
  }

  public get includeProjections(): boolean {
    return this._includeProjections;
  }

  public get suspendDataUpdates(): boolean {
    return this._suspendDataUpdates;
  }
  public set suspendDataUpdates(suspend: boolean) {
    this._suspendDataUpdates = suspend;
  }

  public get dataFilters(): GanttDataFilters {
    return this._dataFilters;
  }

  public get ganttData(): GanttData {
    if (!this._ganttData) {
      throw new Error('Empty gantt data in gantt controller.');
    }
    return this._ganttData;
  }

  // not implemented?
  public get flatBoxList(): GanttBlockEvent[] {
    return this._flatBoxList;
  }
}

class OldGanttConversions {
  private static mapLaneEventColors = (
    state: string | null
  ): { dark: string; light: string } => {
    if (state === null) {
      return { dark: 'green', light: 'green' };
    }
    // temp colors that will go away
    // Yellow: Dark - #F7C85B     Light - #FEFBEF
    // Blue: Dark - #0086F9     Light - #E8F2FF
    // do the color mapping
    const colorMap: { [key: string]: { dark: string; light: string } } = {
      ACTIVITY_TYPE_ACTION_RUNNING: { dark: '#0B83FF', light: '#E8F2FF' },
      ACTIVITY_TYPE_ASSISTANT_RUNNING: { dark: '#0B83FF', light: '#E8F2FF' },
      ACTIVITY_TYPE_ASSISTANCE_NEEDED: { dark: '#F2C94C', light: '#FDFBEE' },
      ACTIVITY_TYPE_ERROR: { dark: '#E02139', light: '#FBE9EC' },
      ACTIVITY_TYPE_PAUSING: { dark: '#054180', light: '#E7ECF3' },
      ACTIVITY_TYPE_PAUSED: { dark: '#054180', light: '#E7ECF3' },
      ACTIVITY_TYPE_UNKNOWN: { dark: '#7B7B7B', light: '#F2F2F2' },
      ACTIVITY_TYPE_ON_HOLD: { dark: '#6061D4', light: '#F0EFFB' },
      ACTIVITY_TYPE_SCHEDULED_ACTION: { dark: '#0B83FF', light: '#E8F2FF' },
      ACTIVITY_TYPE_SCHEDULED_ASSISTANT: { dark: '#F2C94C', light: '#FDFBEE' },
    };
    const color = colorMap[state] || {
      dark: 'black',
      light: 'black',
    };
    // if (color === 'black') {
    //   console.warn(`unknown state:  ${state}`);
    // }
    return color;
  };

  static initGanttData(ganttRequest: GanttRequest, labId: string): GanttData {
    // attempt to find the serviceId for every resource
    // const serviceIdTable: { [key: string]: string } = {};
    // for (const job of ganttRequest.jobs) {
    //   for (const activity of job.activities) {
    //     if (activity.scheduledActor?.actorId) {
    //       // console.log(
    //       //   `actorId: ${activity.scheduledActor.actorId}  has deviceId: ${activity.scheduledActor.deviceId}`
    //       // );
    //       serviceIdTable[activity.scheduledActor.actorId] =
    //         activity.scheduledActor.deviceId || 'fail';
    //     }
    //   }
    // }

    return {
      labId,
      snapshotTime: decodeTimestamp(ganttRequest.projectionsComputedAt),
      lanes: ganttRequest.jobs
        .filter((data) => data.activities.length > 0)
        .map((lane) => {
          const action = ActionController.Instance.getAction(lane.workflowId);
          const blockEvents = lane.activities
            // this line filters out events we want to not show
            // I was asked to remove it, but then told we might bring it back...
            // .filter((data) => data.state !== 'PAUSED')
            .filter((data) => data.scheduledActor?.actorId !== 'expression')
            .map((activity) => {
              return OldGanttConversions.createBlockEvent(
                activity.id,
                activity.name,
                activity.start,
                activity.end,
                activity.state,
                activity.projected,
                lane.name,
                action.name,
                activity.scheduledActor?.actorId || '',
                activity.compatibleActorIds,
                activity.actorRequestIndex,
                activity.constraints?.relativeOffset ?? 0,
                activity.constraints?.rescheduledActorId ?? ''
              );
            });

          const pointEvents = lane.annotations
            // .filter((val) => {
            //   // sometimes these happen way before the job runs, which screws up "zoom to row"
            //   // for now, just remove all job config change annotations
            //   return val.type !== JobEventType.JOB_CONFIG_CHANGED;
            // })
            .map((ev) => {
              // maybe this should move to the sanity checker
              if (ev.timestamp === '') {
                console.warn('timestamp is empty');
              }
              const temp: GanttPointEvent = {
                id: ev.id,
                type: ev.type,
                time: decodeTimestamp(ev.timestamp),
              };
              return temp;
            });

          return OldGanttConversions.createLane(
            lane.id,
            lane.name,
            blockEvents,
            lane.start,
            lane.end,
            pointEvents
          );
        }),
      resoures:
        ganttRequest.actorStatuses?.map((val): GanttResource => {
          if (
            !val.actorId ||
            val.offline === undefined ||
            val.offline === null
          ) {
            console.error('Invalid resource in gantt.');
            return { actorId: 'invalid', offline: true, serviceId: 'invalid' };
          }
          return {
            actorId: val.actorId,
            offline: val.offline,
            serviceId: undefined,
            // serviceId: serviceIdTable[val.actorId],
          };
        }) ?? [],
    };
  }

  static createBlockEvent(
    id: string,
    name: string,
    startTimestamp: string,
    endTimestamp: string,
    state: string | null,
    projected: boolean,
    parentJobName: string,
    workflowName: string,
    resourceId: string,
    compatibleResourceIds: string[],
    actorRequestIndex: number | null,
    relativeOffset: number,
    assignedResource: string
    // color = 'blue'
  ): GanttBlockEvent {
    const start = decodeTimestamp(startTimestamp);
    const end = decodeTimestamp(endTimestamp);
    const color = OldGanttConversions.mapLaneEventColors(state);
    const blockName =
      state === 'ACTIVITY_TYPE_ASSISTANCE_NEEDED'
        ? `Assistance needed with ${name}`
        : name;

    const eventDetails: EventDetailsD3Type = {
      start,
      end,
      titleString: blockName,
      contentString1: parentJobName,
      contentIcon1: 'NoIcon',
      contentString2: workflowName,
      contentIcon2: 'WorkflowIcon',
    };
    return {
      id,
      name: blockName,
      start,
      end,
      relativeOffset,
      assignedResource,
      color,
      drawProjected: projected,
      locked: false,
      noCheckmark: true, // turn off the check mark
      popupDetails: eventDetails,
      resourceId,
      compatibleResourceIds,
      actorRequestIndex,
    };
  }

  static createLane(
    id: string,
    name: string,
    blockEvents: GanttBlockEvent[],
    startTimestamp: string,
    endTimestamp: string,
    pointEvents: GanttPointEvent[]
  ): GanttLane {
    // hack to remove "File Uploaded" events and recast "File Uploading" events
    const pointEvents2 = pointEvents
      .filter((ev) => {
        // temporary hack to remove "File Uploaded events"
        return ev.type !== JobEventType.JOB_FILE_CAPTURED;
      })
      .map((ev) => {
        // temporary hack to convert "File Uploading" events into "File Uploaded" instead
        const eventType =
          ev.type === JobEventType.ACTION_FILE_CAPTURE_INITIATED
            ? JobEventType.JOB_FILE_CAPTURED
            : ev.type;
        return {
          ...ev,
          type: eventType,
        };
      });
    // end hack

    const drawEndCircle = endTimestamp !== '';
    const lastActivityEnd = blockEvents[blockEvents.length - 1].end;
    const end = drawEndCircle ? decodeTimestamp(endTimestamp) : lastActivityEnd;
    const emptyLane: GanttLane = {
      id: id,
      name: name,
      blockEvents,
      pointEvents: pointEvents2,
      start:
        startTimestamp === ''
          ? blockEvents[0].start
          : decodeTimestamp(startTimestamp),
      end,
      drawEndCircle,
    };
    return emptyLane;
  }
}
