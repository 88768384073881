import { reactive } from 'vue';
import UIController from '@/clients/ui';
import * as Apollo from './service.apollo';
import { User, UserRole, nullUser } from './model';
import DeferredPromise from '../DeferredPromise';

interface StateInterface {
  users: User[];
  currentUser: User;
  artificialDeveloperIds: string[];
  isActingAsArtificialUser: boolean;
}

export const getUserRole = (user: User): UserRole[] => {
  return user.userExt?.roles || [];
};

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private allUsersRetrieved: DeferredPromise<void> = new DeferredPromise();

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      users: [],
      currentUser: nullUser(),
      artificialDeveloperIds: [],
      isActingAsArtificialUser:
        localStorage.getItem('isActingAsArtificialUser') !== 'false',
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /*
   * ACTIONS/MUTATIONS
   */

  async dispatchGetWorld() {
    await Promise.all([
      this.dispatchGetUsers(),
      this.dispatchGetCurrentUser(),
      this.dispatchGetArtificialDevelopers(),
    ]);
    this.allUsersRetrieved.resolve();
  }

  async dispatchGetUsers() {
    this.state.users = await Apollo.getUsers();
  }

  async dispatchGetCurrentUser() {
    this.state.currentUser = await Apollo.getCurrentUser();
  }

  async dispatchGetArtificialDevelopers() {
    this.state.artificialDeveloperIds = await Apollo.getArtificialDevelopers();
  }

  async dispatchAddUser(
    name: string,
    email: string,
    role: UserRole
  ): Promise<User> {
    const newUser = await Apollo.addUser(name, email, role);
    if (newUser.id) {
      newUser.name = name;
      newUser.email = email;
      newUser.userExt = {
        hasLoggedIn: false,
        roles: [role],
      };
    }
    this.state.users.push(newUser);
    return newUser;
  }

  async dispatchUpdateUserRoles(id: string, role: UserRole): Promise<User> {
    const updatedUser = await Apollo.updateUserRoles(id, role);
    if (updatedUser.id) {
      const user = this.getUser(id);
      user.userExt.roles = [role];
    }
    return updatedUser;
  }

  /**
   * Create a TOTP secret
   * @returns a TOTP secret
   */
  async dispatchCreateTotp(): Promise<string> {
    return Apollo.createTotp();
  }

  /**
   * Delete a TOTP secret
   * @param token the TOTP token
   * @returns true if the TOTP secret was deleted
   */
  async dispatchDeleteTotp(token: string): Promise<boolean> {
    const deleted = await Apollo.deleteTotp(token);
    if (deleted) {
      this.state.currentUser.totpEnabled = false;
    }
    return deleted;
  }

  /**
   * Verify a TOTP token
   * @param token the TOTP token
   * @returns true if the token is valid
   */
  async dispatchVerifyTotp(token: string): Promise<boolean> {
    const verified = await Apollo.verifyTotp(token);
    if (verified) {
      this.state.currentUser.totpEnabled = true;
    }
    return verified;
  }

  /**
   * For GDPR compliance, etc... accept or revoke cookies
   * @param revoke true if cookies should be revoked, false if they should be accepted
   * @returns false if cookies were revoked, true if they were accepted
   */
  async dispatchAcceptCookies(revoke?: boolean): Promise<boolean> {
    await Apollo.acceptCookies(revoke);
    if (!this.state.currentUser.complianceData) {
      this.state.currentUser.complianceData = {
        cookiesAccepted: null,
        digitalSignatureAccepted: null,
      };
    }
    return (this.state.currentUser.complianceData.cookiesAccepted = !revoke);
  }

  /**
   * Resend an invite to a user
   * @param id the user id
   */
  async dispatchResendInvite(id: string): Promise<User> {
    const user = this.getUser(id);
    if (user) {
      await Apollo.resendInvite(id);
      return user;
    }
    return nullUser();
  }

  /*
   * GETTERS
   */
  get allUsersRetrievedPromise(): DeferredPromise<void> {
    return this.allUsersRetrieved;
  }
  get users() {
    if (this.isActingAsArtificialUser) {
      return this.state.users;
    } else {
      return this.state.users.filter(
        (u) => !this.isUserArtificialDeveloper(u.id)
      );
    }
  }

  get usersWithAccess() {
    return this.state.users.filter(
      (u) =>
        getUserRole(u).includes(UserRole.ADMIN) ||
        getUserRole(u).includes(UserRole.MEMBER)
    );
  }

  get usersWithoutAccess() {
    return this.state.users.filter((u) =>
      getUserRole(u).includes(UserRole.NONE)
    );
  }

  // the users who have access to the instance (not including artificial developers)
  // unless the current user is an artificial developer, in which case all users are returned
  get instanceUsers() {
    if (this.isActingAsArtificialUser) {
      return this.usersWithAccess;
    }
    return this.usersWithAccess.filter(
      (u) => !this.isUserArtificialDeveloper(u.id)
    );
  }

  get currentUser() {
    return this.state.currentUser;
  }

  get isActingAsArtificialUser() {
    return (
      this.isCurrentUserArtificialDeveloper() &&
      this.state.isActingAsArtificialUser
    );
  }

  set isActingAsArtificialUser(value: boolean) {
    if (!this.isCurrentUserArtificialDeveloper()) return;
    this.state.isActingAsArtificialUser = value;
    localStorage.setItem('isActingAsArtificialUser', value ? '' : 'false');
    // the expert mode switch tracks the value of isActingAsArtificialUser
    UIController.Instance.expert = value;
  }

  getUser(id: string): User {
    return this.state.users.find((u) => u.id === id) || nullUser();
  }

  isUserArtificialDeveloper(id: string): boolean {
    return this.getUser(id).userExt?.roles.includes(UserRole.ARTIFICIAL);
  }

  isCurrentUserArtificialDeveloper(): boolean {
    return this.isUserArtificialDeveloper(this.state.currentUser.id);
  }

  isAdmin(id: string): boolean {
    return this.getUser(id).userExt?.roles.includes(UserRole.ADMIN);
  }

  isMember(id: string): boolean {
    return (
      this.getUser(id).userExt?.roles.includes(UserRole.MEMBER) &&
      !this.isAdmin(id)
    );
  }

  isCurrentUserValid(): boolean {
    return (
      this.isCurrentUserArtificialDeveloper() ||
      this.isAdmin(this.currentUser.id) ||
      this.isMember(this.currentUser.id)
    );
  }
}
