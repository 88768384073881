<template>
  <div id="script-holder">
    <div class="privacy-policy">
      <h2>Privacy Policy</h2>
      <div
        id="policy"
        data-policy-key="WXpjck1XVlVTRmMzUTFwUlkwRTlQUT09"
        data-extra="h-align=left&amp;h-depth=3&amp;no-title=true&amp;table-style=accordion"
      >
        Please wait while the policy is loaded. If it does not load, please
        <a
          rel="nofollow"
          href="https://app.termageddon.com/api/policy/WXpjck1XVlVTRmMzUTFwUlkwRTlQUT09?h-align=left&amp;h-depth=3&amp;no-title=true&amp;table-style=accordion"
          target="_blank"
          aria-label="View Privacy Policy"
          >click here to view the privacy policy</a
        >.
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  const policy = document.getElementById('policy');

  var pol_key = policy.dataset.policyKey;
  var pol_extra = policy.dataset.extra ? policy.dataset.extra : '';

  var xhr = new XMLHttpRequest();

  xhr.onload = function () {
    console.log('Policy loaded!');
    policy.innerHTML = xhr.responseText;
  };

  xhr.onerror = function () {
    console.log('Error loading policy, attempting alternative retrieval');
    let reqHeader = new Headers();
    reqHeader.append('Content-Type', 'text/html');

    let initObject = {
      method: 'GET',
      headers: reqHeader,
    };

    var userRequest = new Request(
      'https://app.termageddon.com/api/policy/' +
        pol_key +
        '?' +
        pol_extra +
        '&origin=' +
        window.location.href,
      initObject
    );

    fetch(userRequest)
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        console.log('Policy loaded via alternative method!');
        policy.innerHTML = data;
      })
      .catch(function (err) {
        console.log('Error loading policy via alternative method');
        policy.innerHTML =
          "There was an error loading this policy, please <a href='https://app.termageddon.com/api/policy/" +
          pol_key +
          '?' +
          pol_extra +
          '&origin=' +
          window.location.href +
          "'>click here</a> to view it.";
      });
  };

  xhr.open(
    'GET',
    'https://app.termageddon.com/api/policy/' +
      pol_key +
      '?' +
      pol_extra +
      '&origin=' +
      window.location.href
  );
  xhr.send();
});
</script>

<style lang="scss" scoped>
#script-holder {
  background-color: white;
  font-family: Inter;
  font-size: 24px;
}

.privacy-policy {
  width: 960px;
  margin: 0 auto;
  padding: 30px 0;
}

#policy {
  font-size: 14px;
  margin-top: 30px;

  :deep(p),
  :deep(ul),
  :deep(ol),
  :deep(h1),
  :deep(h2),
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  :deep(ul),
  :deep(ol) {
    padding-left: 2rem;
  }

  :deep(.accordion-content) {
    display: block !important;
  }
}
</style>
