import { gql } from '@apollo/client/core';
import { User, UserRole, nullUser } from './model';
import { apollo } from '../service.apollo';

export async function getUsers(): Promise<User[]> {
  const result = await apollo.query<{ allUsers: User[] }>({
    query: gql`
      query GetUsers {
        allUsers {
          id
          name
          email
          profileImage
          userExt {
            hasLoggedIn
            roles
          }
        }
      }
    `,
  });

  return result.data.allUsers.map((u) => ({
    ...nullUser(),
    ...u,
  }));
}

export async function getCurrentUser(): Promise<User> {
  const result = await apollo.query<{ user: User }>({
    query: gql`
      query GetCurrentUser {
        user {
          id
          name
          email
          orgId
          totpEnabled
          userExt {
            hasLoggedIn
            roles
          }
          complianceData {
            cookiesAccepted
            digitalSignatureAccepted
          }
        }
      }
    `,
  });

  return {
    ...nullUser(),
    ...result.data.user,
  };
}

export async function getArtificialDevelopers(): Promise<string[]> {
  const result = await apollo.query<{ artificialDevelopers: User[] }>({
    query: gql`
      query GetArtificialDevelopers {
        artificialDevelopers {
          id
        }
      }
    `,
  });

  return result.data.artificialDevelopers.map((u) => u.id);
}

export async function addUser(
  name: string,
  email: string,
  role: UserRole
): Promise<User> {
  const result = await apollo.mutate<{ addUser: User }>({
    variables: { name, email, role },
    mutation: gql`
      mutation AddUser($name: String!, $email: String!, $role: UserRole!) {
        addUser(name: $name, email: $email, role: $role) {
          id
        }
      }
    `,
  });

  return result.data?.addUser || nullUser();
}

export async function updateUserRoles(
  userId: string,
  role: UserRole
): Promise<User> {
  const result = await apollo.mutate<{ updateUserRoles: User }>({
    variables: { userId, roles: [role] },
    mutation: gql`
      mutation UpdateUserRoles($userId: ID!, $roles: [UserRole!]) {
        updateUserRoles(userId: $userId, roles: $roles) {
          id
          userExt {
            roles
          }
        }
      }
    `,
  });

  return result.data?.updateUserRoles || nullUser();
}

export async function acceptCookies(revoke?: boolean): Promise<boolean> {
  const result = await apollo.mutate<{ setCookieAcceptance: boolean }>({
    variables: { revoke },
    mutation: gql`
      mutation AcceptCookies($revoke: Boolean) {
        setCookieAcceptance(revoke: $revoke)
      }
    `,
  });

  return !!result.data?.setCookieAcceptance;
}

export async function createTotp(): Promise<string> {
  const result = await apollo.mutate<{ createTOTP: string }>({
    mutation: gql`
      mutation CreateTotp {
        createTOTP
      }
    `,
  });

  return result.data?.createTOTP || '';
}

export async function deleteTotp(token: string): Promise<boolean> {
  try {
    const result = await apollo.mutate<{ deleteTOTP: boolean }>({
      variables: { token },
      context: {
        ignoreErrorNotifications: true,
      },
      mutation: gql`
        mutation DeleteTotp($token: String) {
          deleteTOTP(token: $token)
        }
      `,
    });

    return result.data?.deleteTOTP || false;
  } catch (_) {
    return false;
  }
}

export async function verifyTotp(token: string): Promise<boolean> {
  try {
    const result = await apollo.mutate<{ verifyTOTP: boolean }>({
      variables: { token },
      context: {
        ignoreErrorNotifications: true,
      },
      mutation: gql`
        mutation VerifyTotp($token: String) {
          verifyTOTP(token: $token)
        }
      `,
    });

    return result.data?.verifyTOTP || false;
  } catch (_) {
    return false;
  }
}

export async function resendInvite(userId: string): Promise<boolean> {
  const result = await apollo.mutate<{ resendInvite: boolean }>({
    variables: { userId },
    mutation: gql`
      mutation resendInvite($userId: ID!) {
        resendInvite(userId: $userId)
      }
    `,
  });

  return !!result.data?.resendInvite;
}
