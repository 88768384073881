import { GanttRequest } from '@/clients/ops/model';

export const testData: GanttRequest = {
  projectionsComputedAt: '2024-12-09T19:45:41.151551+00:00',
  jobs: [
    {
      name: 'Job 0',
      id: 'job_id_0',
      workflowId: '',
      start: '2024-12-09T19:45:41.151551+00:00',
      end: '',
      activities: [
        {
          name: 'init',
          id: 'job_id_0_init_abc25d7d6b9c1669d396e86e3b9565b58eb7f502program_6cba8e5c-a1e1-41ce-95cb-5135ec36de6b',
          start: '2024-12-09T19:45:41.151551+00:00',
          end: '2024-12-09T19:45:42.151551+00:00',
          state: 'ACTIVITY_TYPE_SCHEDULED_ACTION',
          actionId: 'job_id_0_init_abc25d7d6b9c1669d396e86e3b9565b58eb7f502',
          actionType: 'ACTION_TYPE_EXPRESSION',
          scheduledActor: {
            actorId: 'expression',
          },
          constraints: null,
          compatibleActorIds: [],
          actorRequestIndex: null,
          projected: true,
        },
      ],
      annotations: [],
    },
  ],
  actorStatuses: [],
};
