import { EventDispatcher } from 'three';
import { reactive } from 'vue';
import { cloneDeep, isEqualWith } from 'lodash';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { JobFilters } from '@/clients/ops';
import { Buffer } from 'buffer';
import { DateTime } from 'luxon';
import { decodeTimestamp, encodeTimestamp } from './ganttModel';

// fancy TS to make my own version of JobFilters that requires labId
type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
type MyJobFilters = WithRequired<JobFilters, 'labId'>;

interface GanttViewEventMap {
  // UPDATE: { newValue: GanttData; oldValue: GanttData | undefined };
  UPDATE: { newState: GanttStateType; oldState: GanttStateType };
  DISPOSE: object;
}

// the types used to actually store data in the route
// all fields optional to force checking that it existed when parsed from route
interface GanttRouteViewType {
  focusJob?: string;
  dateRange?: {
    start?: string;
    end?: string;
  };
  rowScroll?: number;
}
interface GanttRouteType {
  sourceId?: string;
  filters?: JobFilters;
  view?: GanttRouteViewType;
}

// the types exposed to the public from this file
// no fields optional, explicit null for no value
export interface GanttStateViewType {
  focusJob: string | null;
  dateRange: { start: DateTime; end: DateTime } | null;
  rowScroll: number | null;
}
// don't make anything optional
export interface GanttStateType {
  sourceId: string | null;
  filters: MyJobFilters;
  view: GanttStateViewType | null;
}
interface StateInterface {
  currentStartDate: DateTime;
  currentEndDate: DateTime;
}

/**
 * Centralized store for the gantt view related parameters
 * This includes the filter for the data and the view pan/zoom
 * Also optionally includes reading from and writing to the route for these params
 */
export default class GanttViewController extends EventDispatcher<GanttViewEventMap> {
  private _ganttState: GanttStateType;
  // maybe pull these into the normal gantt view stuff and make it mandatory?
  private internalState: StateInterface;
  private _updateInProgress: Promise<unknown> = Promise.resolve();

  /**
   * Create a new route controller.
   * @param jobFilters Filters to use for the gantt data queries - currently only supports labId and jobIds (more?) - only used if no route
   * @param router If provided, allows the gantt chart to read/write it's view parameters in the route
   * @param route If provided, allows the gantt chart to write it's view parameters in the route
   */
  constructor(
    jobFilters: JobFilters,
    private readonly router?: Router,
    private readonly route?: RouteLocationNormalizedLoaded
  ) {
    super();

    // some checks to ensure mandatory fields are filled out
    if (!jobFilters.labId) {
      throw new Error('Must have a labId in ganttViewController');
    }

    this._ganttState = {
      sourceId: null,
      filters: {
        ...cloneDeep(jobFilters),
        labId: jobFilters.labId, // TS was mad without this
      },
      view: {
        focusJob: null,
        dateRange: null,
        rowScroll: null,
      },
    };

    this.internalState = reactive({
      currentStartDate: DateTime.now(),
      currentEndDate: DateTime.now(),
    });

    const initialState = this.getStateFromRoute();
    if (initialState) {
      this.handleStateUpdate(initialState);
    }

    if (router) {
      router.afterEach((route) => {
        // need to update our state from the route, then send it in the event
        const newState = this.getStateFromRoute(route);
        // console.log('route afterEach:  ', newState);
        if (newState) {
          this.handleStateUpdate(newState);
        }
      });
    }
    // console.log('initial view state:  ', this._ganttState);
  }

  dispose() {
    console.log('disposing GanttViewController');
    // how should this work?  notifications to things that are asking for timed updates?
    this.dispatchEvent({ type: 'DISPOSE' });
  }

  // private async initGanttController() {
  //   await this.updateGanttData(this.dataFilters);
  //   return true;
  // }

  private static encodeState(routeState: GanttRouteType): string {
    return Buffer.from(JSON.stringify(routeState)).toString('base64');
  }

  private static decodeState(routeString: string): GanttRouteType {
    try {
      return JSON.parse(Buffer.from(routeString, 'base64').toString('utf-8'));
    } catch (err) {
      // do whatever with err
      console.log('Exception trying to parse gantt route state.');
      return {};
    }
  }

  // turns a GanttStateRoute into a GanttStateType
  private static routeToType(routeState: GanttRouteType): GanttStateType {
    const labId = routeState.filters?.labId;
    if (!labId) {
      throw new Error('We must have a labId in the gantt route state.');
    }
    const startDateString = routeState.view?.dateRange?.start;
    const endDateString = routeState.view?.dateRange?.end;
    const dateRange =
      startDateString && endDateString
        ? {
            start: decodeTimestamp(startDateString),
            end: decodeTimestamp(endDateString),
          }
        : null;
    const sourceId = routeState.sourceId || null;

    const ganttState: GanttStateType = {
      sourceId,
      filters: {
        ...(routeState.filters || {}),
        labId,
      },
      view: {
        dateRange,
        focusJob: routeState.view?.focusJob || null,
        rowScroll: routeState.view?.rowScroll ?? null,
      },
    };
    return ganttState;
  }

  private static typeToRoute(typeState: GanttStateType): GanttRouteType {
    const ganttRoute: GanttRouteType = {
      filters: {
        labId: typeState.filters.labId,
        // add supported filters here
      },
    };
    if (typeState.sourceId) {
      ganttRoute.sourceId = typeState.sourceId;
    }
    if (typeState.view) {
      ganttRoute.view = {};
      if (typeState.view.dateRange) {
        ganttRoute.view.dateRange = {
          start: encodeTimestamp(typeState.view.dateRange.start),
          end: encodeTimestamp(typeState.view.dateRange.end),
        };
      }
      if (typeState.view.focusJob) {
        ganttRoute.view.focusJob = typeState.view.focusJob;
      }
      if (typeState.view.rowScroll !== null) {
        ganttRoute.view.rowScroll = typeState.view.rowScroll;
      }
    }
    return ganttRoute;
  }

  // compares the contents of the states but ignores their IDs
  static compareStates(
    state1: GanttStateType,
    state2: GanttStateType
  ): boolean {
    // compare every field but skip the sourceId
    return isEqualWith(state1, state2, (_val1, _val2, key) =>
      key === 'sourceId' ? true : undefined
    );
  }

  private getStateFromRoute(
    route?: RouteLocationNormalizedLoaded
  ): GanttStateType | undefined {
    if (!this.router) {
      return undefined;
    }
    if (!route) {
      route = this.router.currentRoute.value;
    }
    const ganttRouteString = route.query['ganttState']?.toString();
    if (!ganttRouteString) {
      // console.log('Could not get route string.');
      return undefined;
    }

    const ganttRouteState = GanttViewController.decodeState(ganttRouteString);
    const ganttState = GanttViewController.routeToType(ganttRouteState);
    return ganttState;
  }

  private static async setRouteFromState(
    state: GanttStateType,
    router: Router,
    route: RouteLocationNormalizedLoaded
  ): Promise<void> {
    const encodedState = GanttViewController.encodeState(
      GanttViewController.typeToRoute(state)
    );
    await router.push({
      ...route,
      query: { view: 'gantt', ganttState: encodedState },
    });
  }

  private handleStateUpdate(newState: GanttStateType) {
    // ignore if it didn't change
    if (GanttViewController.compareStates(newState, this._ganttState)) {
      // console.log('handleStateUpdate() dropped an update because no change');
      return;
    }
    const oldState = this._ganttState;
    this._ganttState = newState;
    // temporary hacky way to have a reactive version of start/end
    // should unify this with the real view data when I have more time
    const dateRange = this._ganttState.view?.dateRange;
    if (dateRange) {
      this.currentStartDate = dateRange.start;
      this.currentEndDate = dateRange.end;
    }
    // console.log('New gantt view state:  ', this._ganttState);
    this.dispatchEvent({ type: 'UPDATE', newState, oldState });
  }

  // protects calls to updateStateInternal til they are safe to run
  // returns the state it is attempting to set
  private async updateState(state: GanttStateType): Promise<GanttStateType> {
    // console.log('attempting to updateState to:  ', state);
    // make sure we resolve any pending updates heading for the route
    const pendingUpdate = this._updateInProgress;

    const newState = (async () => {
      await pendingUpdate;
      // ignore if it didn't change
      if (GanttViewController.compareStates(state, this._ganttState)) {
        // console.log('updateState() dropped the update because no change');
        return this._ganttState;
      }
      if (this.router && this.route) {
        // if connected to the route, store the state and let it send the event
        await GanttViewController.setRouteFromState(
          state,
          this.router,
          this.route
        );
        // console.log('route update complete');
      } else {
        // otherwise, manually update the state and send the event
        this.handleStateUpdate(state);
      }
      return state;
    })();

    this._updateInProgress = newState;
    return newState;
  }

  /**
   * Set the gantt to focus on the specified job.
   * @param jobId The ID of the job to focus. Empty sets to none.
   * @param sourceId Optional ID of source of update.  Useful for filtering echo events.
   * @returns The resulting state data.
   */
  setFocusJob(jobId?: string, sourceId?: string): Promise<GanttStateType> {
    const newState: GanttStateType = {
      sourceId: sourceId || null,
      filters: {
        labId: this.labId,
      },
      view: {
        focusJob: jobId || null,
        // don't clear the other fields
        dateRange: this._ganttState.view?.dateRange ?? null,
        rowScroll: this._ganttState.view?.rowScroll ?? null,
      },
    };
    return this.updateState(newState);
  }

  /**
   * Set the gantt to focus on a specific time range.
   * @param startDate
   * @param endDate
   * @param rowScroll optional vertical scroll amount.
   * @param sourceId Optional ID of source of update.  Useful for filtering echo events.
   * @returns The resulting state data.
   */
  setFocusInterval(
    startDate: DateTime,
    endDate: DateTime,
    rowScroll?: number,
    sourceId?: string
  ): Promise<GanttStateType> {
    const newState: GanttStateType = {
      sourceId: sourceId || null,
      filters: { labId: this.labId },
      view: {
        dateRange: {
          start: startDate,
          end: endDate,
        },
        rowScroll: rowScroll ?? null,
        focusJob: null,
      },
    };
    return this.updateState(newState);
  }

  /**
   *  "Now" time is defined as 12 hour zoom with "now" at 3/4 of x range
   */
  setViewNow(
    latestUpdateTime: DateTime,
    initialScaleHours: number,
    sourceId?: string
  ): Promise<GanttStateType> {
    const startTime = latestUpdateTime.minus({
      hour: initialScaleHours * 0.75,
    });
    const endTime = startTime.plus({ hour: initialScaleHours });
    return this.setFocusInterval(startTime, endTime, 0, sourceId);
  }

  /**
   * Pans chart in X axis based on scale, +1 means .5 of time domain to the right
   * @param scale
   * @returns
   */
  panView(
    domainStartDate: DateTime,
    domainEndDate: DateTime,
    scale: number,
    sourceId?: string
  ): Promise<GanttStateType> {
    const timeShift = {
      hours: scale * (domainEndDate.diff(domainStartDate, 'hours').hours / 2),
    };
    return this.setFocusInterval(
      domainStartDate.minus(timeShift),
      domainEndDate.minus(timeShift),
      undefined,
      sourceId
    );
  }

  setDomainHours(
    domainStartDate: DateTime,
    domainEndDate: DateTime,
    newDomainHours: number,
    sourceId?: string
  ): Promise<GanttStateType> {
    const dateCenter = domainStartDate.plus({
      hours: domainEndDate.diff(domainStartDate, 'hours').hours / 2,
    });
    const dateOffset = { hours: newDomainHours / 2 };
    return this.setFocusInterval(
      dateCenter.minus(dateOffset),
      dateCenter.plus(dateOffset),
      undefined,
      sourceId
    );
  }

  setStartDate(
    domainStartDate: DateTime,
    domainEndDate: DateTime,
    newDate: DateTime,
    sourceId?: string
  ): Promise<GanttStateType> {
    const timeShift = newDate.diff(domainStartDate);
    const endDate = domainEndDate.plus(timeShift);

    return this.setFocusInterval(newDate, endDate, undefined, sourceId);
  }

  /**
   * ACCESSORS
   */

  public get labId(): string {
    return this._ganttState.filters.labId;
  }

  public get state(): GanttStateType {
    return cloneDeep(this._ganttState);
  }

  public get currentStartDate(): DateTime {
    return this.internalState.currentStartDate;
  }

  private set currentStartDate(val: DateTime) {
    this.internalState.currentStartDate = val;
  }

  public get currentEndDate(): DateTime {
    return this.internalState.currentEndDate;
  }

  private set currentEndDate(val: DateTime) {
    this.internalState.currentEndDate = val;
  }

  // public get filters(): MyJobFilters {
  //   return cloneDeep(this._ganttState.filters);
  // }

  // public get view(): GanttStateViewType | null {
  //   return this._ganttState.view ? cloneDeep(this._ganttState.view) : null;
  // }
}
